import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

interface RouteProps {
  children: JSX.Element;
}

export function PrivateRoute({ children }: RouteProps): JSX.Element {
  const { user } = useAuth();
  const { state } = useLocation();

  if (!user) {
    return <Navigate to="/signin" state={state} />;
  }

  return children;
}

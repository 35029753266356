/* eslint-disable no-plusplus */
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import SignaturePad from "react-signature-canvas";
import { FormButton } from "../../../components/FormButton";
import { Loading } from "../../../components/Loading";
import { api } from "../../../services/api";

interface ModalAddSignatureProps {
  id: string;
  type: string;
  setModalAddSignature(showModal: boolean): void;
  setTechnicianSignature(signature: boolean): void;
  setResponsibleSignature(signature: boolean): void;
}

export function ModalAddSignature({
  id,
  type,
  setModalAddSignature,
  setTechnicianSignature,
  setResponsibleSignature,
}: ModalAddSignatureProps) {
  const [loading, setLoading] = useState(false);
  const signCanvas = React.useRef() as React.MutableRefObject<any>;
  const clearSignature = () => signCanvas.current.clear();

  function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  async function saveResponsibleSignature() {
    try {
      setLoading(true);
      const fileResponsible = DataURIToBlob(
        signCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
      );

      const signature = new FormData();

      signature.append("signature_file", fileResponsible, "image.jpg");

      await api.patch(`/techVisitReport/${type}/${id}`, signature);

      setModalAddSignature(false);

      if (type === "technician_signature") {
        setTechnicianSignature(true);
      } else if (type === "responsible_signature") {
        setResponsibleSignature(true);
      }

      return setLoading(false);
    } catch (e) {
      setLoading(false);
      return setModalAddSignature(false);
    }
  }

  const title = type === "responsible_signature" ? "Cliente" : "Técnico";

  return (
    <>
      {loading && <Loading />}
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      <div className="fixed inset-0 overflow-auto text-white">
        <div className="flex items-center justify-center min-h-full p-0">
          <div className="relative bg-[#77C5E5] rounded-lg p-4 shadow-xl transform transition-all w-full m-2">
            <div className="flex justify-between items-center border-b pb-2 mb-4">
              <h1 className="text-2xl font-bold">Assinatura {title}</h1>
              <button type="button" onClick={() => setModalAddSignature(false)}>
                <AiOutlineClose />
              </button>
            </div>
            <SignaturePad
              ref={signCanvas}
              clearOnResize
              canvasProps={{
                className: "mt-1 bg-white rounded-lg w-full h-40",
              }}
            />
            <div className="flex gap-1">
              <FormButton
                type="button"
                title="Apagar"
                onClick={() => clearSignature()}
              />
            </div>
            <div className="flex space-x-2 justify-start mt-6">
              <button
                onClick={() => setModalAddSignature(false)}
                className="w-full min-h-[56px] text-sm text-white rounded-lg bg-red-500 hover:bg-red-700 transition-colors"
              >
                <span className="font-medium">Fechar</span>
              </button>
              <button
                type="button"
                className="w-full min-h-[56px] text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                onClick={() => {
                  saveResponsibleSignature();
                }}
              >
                <span className="font-medium">Salvar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import Select from "react-select";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SelectMultiple({ ...props }: any) {
  return (
    <>
      <Select name="services" isMulti {...props} />
      <div className="text-xs text-red-500">{props.error}</div>
    </>
  );
}

import { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
}

export function FormButton({ title, ...rest }: ButtonProps) {
  return (
    <button
      className="w-full mt-4 min-h-[56px] text-sm font-medium text-white rounded-lg bg-[#0085BD]"
      {...rest}
    >
      {title}
    </button>
  );
}

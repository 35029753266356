import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";

import { Header } from "../../components/Header";
import { ItemPagination } from "../../components/ItemPagination";
import { api } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import { PageTitle } from "../../components/PageTitle";

interface Responsible {
  id: string;
  name: string;
  status: string;
  costumer: Costumer;
}

interface Costumer {
  id: string;
  name: string;
}

export function Responsibles() {
  const { user } = useContext(AuthContext);
  const [responsibles, setResponsibles] = useState<Responsible[]>([]);
  const [costumers, setCostumers] = useState<Costumer[]>([]);
  const [costumer_id, setCostumerId] = useState("");
  const [changeStatus, setChangeStatus] = useState<number>(0);

  useEffect(() => {
    api.get("/responsibles").then(response => {
      return setResponsibles(response.data);
    });

    api.get("/costumers").then(response => {
      return setCostumers(response.data);
    });
  }, []);

  useEffect(() => {
    api
      .get("/responsibles", {
        params: {
          costumer_id,
        },
      })
      .then(response => {
        return setResponsibles(response.data);
      });
  }, [costumer_id, changeStatus]);

  async function handleChangeStatus(id: string, newStatus: string) {
    api.patch(`responsibles/status/${id}/${newStatus === "A" ? "I" : "A"}`);
    setTimeout(() => {
      setChangeStatus(changeStatus + 1);
    }, 1000);
  }

  return (
    <div className="h-screen">
      <Header userName={user?.name} />
      <div className="md:w-auto xl:w-[400px] mx-6 mt-5">
        <PageTitle title="Responsáveis" backButton backPath="/" />
        <div className="w-[52px] h-[52px] mt-1 flex items-center justify-center bg-[#0085BD] rounded-lg">
          <Link to="/responsibles/new">
            <FiPlus size={32} className="text-white" />
          </Link>
        </div>
        <div className="mt-1">
          <span className="text-small text-white">Cliente</span>
          <select
            name="costumer_id"
            onChange={e => setCostumerId(e.target.value)}
            className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
          >
            <option value="" defaultChecked>
              Todos
            </option>
            {costumers &&
              costumers.map(({ id: costumerId, name }: Costumer) => (
                <option key={costumerId} value={costumerId}>
                  {name}
                </option>
              ))}
          </select>
        </div>
        {responsibles.length > 0 ? (
          responsibles.map(({ id, name, costumer, status }: Responsible) => (
            <ItemPagination
              key={id}
              title={name}
              description={costumer.name}
              id={id}
              status={status}
              hasDelete
              handleChangeStatus={() => handleChangeStatus(id, status)}
            />
          ))
        ) : (
          <div className="w-full mt-2 p-5 rounded-lg bg-[#0085BD]">
            <span className="text-sm text-medium text-white">
              Nenhum registro foi encontrado 👀
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { AiOutlineClose } from "react-icons/ai";
import { Input } from "../../../components/Input";
import { api } from "../../../services/api";

interface ModalAddResponsibleProps {
  setModalAddResponsible(showModal: boolean): void;
  costumer_id: string;
}

interface Responsible {
  newResponsible_name: string;
}

interface SelectCostumers {
  id: string;
  name: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Campo setor é obrigatório"),
});

export function ModalAddResponsible({
  setModalAddResponsible,
  costumer_id,
}: ModalAddResponsibleProps) {
  const initialValues: Responsible = {
    newResponsible_name: "",
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      <div className="fixed inset-0 overflow-auto text-white">
        <div className="flex items-center justify-center min-h-full p-0">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={(values: Responsible) => {
              console.log(1);
            }}
          >
            {({ errors, setFieldValue, values }) => (
              <Form>
                <div className="relative bg-[#77C5E5] rounded-lg p-4 shadow-xl transform transition-all">
                  <div className="flex justify-between items-center border-b pb-2 mb-4">
                    <h1 className="text-2xl font-bold">Novo Responsável</h1>
                    <button
                      type="button"
                      onClick={() => setModalAddResponsible(false)}
                    >
                      <AiOutlineClose />
                    </button>
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <span className="text-sm text-white">Responsável</span>
                      <Input
                        type="text"
                        name="newResponsible_name"
                        placeholder="Nome do Responsável"
                        error={errors.newResponsible_name}
                      />
                    </div>
                  </div>
                  <div className="flex space-x-2 justify-start mt-6">
                    <button
                      onClick={() => setModalAddResponsible(false)}
                      className="w-full min-h-[56px] text-sm text-white rounded-lg bg-red-500 hover:bg-red-700 transition-colors"
                    >
                      <span className="font-medium">Fechar</span>
                    </button>
                    <button
                      type="button"
                      className="w-full min-h-[56px] text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                      onClick={async () => {
                        await api.post("/responsibles", {
                          costumer_id,
                          name: values.newResponsible_name,
                          email: "",
                          phone: 0,
                        });
                        return setModalAddResponsible(false);
                      }}
                    >
                      <span className="font-medium">Salvar</span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

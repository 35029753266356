/* eslint-disable import/no-extraneous-dependencies */
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { AuthProvider } from "./contexts/AuthContext";

import { Routes } from "./routes";

export function App() {
  return (
    <SnackbarProvider>
      <AuthProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AuthProvider>
    </SnackbarProvider>
  );
}

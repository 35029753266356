import { Field } from "formik";
import { InputHTMLAttributes } from "react";
import { IconBaseProps } from "react-icons";

interface InputMaskProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ComponentType<IconBaseProps>;
  error?: string;
  value: string;
}

function getClasses(error) {
  return error
    ? {
        div: "w-full min-h-[52px] flex items-center bg-white rounded-lg border-2 border-red-500",
        icon: "ml-4 text-red-500",
        field:
          "px-4 w-full h-full text-sm rounded-lg placeholder-red-500 text-red-500 focus:outline-none focus:ring-0 border-none",
      }
    : {
        div: "w-full min-h-[52px] flex items-center bg-white rounded-lg",
        icon: "ml-4 text-terciary-700",
        field:
          "px-4 w-full h-full text-sm rounded-lg placeholder-terciary-700 text-terciary-700 focus:outline-none focus:ring-0 border-none",
      };
}

export function InputMask({
  icon: Icon,
  error,
  value,
  ...rest
}: InputMaskProps) {
  // console.log("error", error);
  return (
    <>
      <div className={getClasses(error).div}>
        {Icon && <Icon size={24} className={getClasses(error).icon} />}
        <Field value={value} className={getClasses(error).field} {...rest} />
      </div>
      <div className="mb-1 text-xs text-red-500">{error}</div>
    </>
  );
}

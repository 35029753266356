/* eslint-disable no-param-reassign */
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

// const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

const PROD = {
  baseURL: "https://api.luvi.app",
};

const PRODIP = {
  baseURL: "http://3.91.208.146",
};

const DEV = {
  baseURL: "http://localhost:3333",
};

export const api: AxiosInstance = axios.create({
  baseURL: PROD.baseURL,
});

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem("@LuviApp:token");
    if (token) {
      config = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

import { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
}

export function Button({ title, ...rest }: ButtonProps) {
  return (
    <button
      className="w-full mt-6 min-h-[56px] text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
      {...rest}
    >
      {title}
    </button>
  );
}

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/order */
/* eslint-disable no-else-return */
/* eslint-disable import/no-duplicates */
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, FieldArray, Form, Formik } from "formik";
import { MdOutlineDelete } from "react-icons/md";
import {
  IoIosArrowDown,
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
  IoIosArrowUp,
} from "react-icons/io";
import { FiPlus, FiTrash } from "react-icons/fi";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import * as Yup from "yup";
import { AiOutlinePlus } from "react-icons/ai";
import { api } from "../../../services/api";
import { Header } from "../../../components/Header";
import { PageTitle } from "../../../components/PageTitle";
import { AuthContext } from "../../../contexts/AuthContext";
import { FormButton } from "../../../components/FormButton";
import { UploadComponent } from "../components/UploadComponent";
import { SelectSingle } from "../../../components/SelectSingle";
import { SelectMultiple } from "../../../components/SelectMultiple";
import { DateHourPicker } from "../../../components/DateHourPicker";
import "react-datepicker/dist/react-datepicker.css";
import { ModalAddSector } from "../components/ModalAddSector";
import ModalImage from "react-modal-image";
import { ModalAddCostumer } from "../components/ModalAddCostumer";
import { BsCloudArrowUp } from "react-icons/bs";
import { Loading } from "../../../components/Loading";
import imageCompression from "browser-image-compression";
import moment from "moment";

registerLocale("ptBR", ptBR);

interface SelectSectors {
  id: string;
  name: string;
}

interface SelectCostumers {
  id: string;
  name: string;
}

interface SelectServices {
  [x: string]: any;
  id: string;
  name: string;
}

interface SelectEquipments {
  id: string;
  name: string;
  grade: string;
}

interface SelectResponsible {
  id: string;
  name: string;
}

interface SelectTechnician {
  id: string;
  name: string;
}

interface FormServices {
  id: string;
}

interface Equipment {
  name: string;
}

interface FormEquipments {
  equipment_id: string;
  name: string;
  grade: string;
  equipment: Equipment;
}

interface FormSectors {
  id: string;
  sector_id: string;
  equipments: FormEquipments[];
  observation: string;
  images: any;
  show: boolean;
}

interface FormTechVisitReport {
  costumer_id: string;
  user_id: string;
  begin: string;
  end: string;
  observation: string;
  pending: string;
  sectors: FormSectors[];
  services: FormServices[];
  responsible_id: string;
  technician_id: string;
  responsible_signature: string;
  technician_signature: string;
  status: string;
}

interface TechVisitReport {
  costumer_id: string;
  user_id: string;
  begin: string;
  end: string;
  observation: string;
  pending: string;
  sectors: FormSectors[];
  services: [];
  responsible_id: string;
  technician_id: string;
  responsible_signature: string;
  technician_signature: string;
  status: string;
}

interface Comments {
  id: string;
  user: {
    name: string;
  };
  comment: string;
  created_at: string;
}

const schema = Yup.object().shape({
  begin: Yup.string().required("Selecione uma data e hora"),
  costumer_id: Yup.string().required("Selecione um cliente"),
  services: Yup.array().of(Yup.string()).required("Selecione os serviços"),
  pending: Yup.string().required("Selecione se existe pendência ou não"),
});

export function TechVisitReportEdit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [report, setReport] = useState<TechVisitReport>();
  const [selectSectors, setSelectSectors] = useState<SelectSectors[]>([]);
  const [selectCostumers, setSelectCostumers] = useState<SelectCostumers[]>([]);
  const [selectServices, setSelectServices] = useState<SelectServices[]>([]);
  const [selectEquipments, setSelectEquipments] = useState<FormEquipments[]>(
    [],
  );
  const [selectResponsibles, setSelectResponsibles] = useState<
    SelectResponsible[]
  >([]);
  const [selectTechnicians, setSelectTechnicians] = useState<
    SelectTechnician[]
  >([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesForm, setSelectedServicesForm] = useState([]);
  const [idCostumer, setIdCostumer] = useState("");
  const [modalAddSector, setModalAddSector] = useState(false);
  const [modalAddCostumer, setModalAddCostumer] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [comments, setComments] = useState<Comments[]>([]);
  const [comment, setComment] = useState("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    api.get(`techVisitReport/${id}`).then(response => {
      const serviceOptions = response.data.services.map(
        ({ id: service_id, name }: SelectServices) => {
          return {
            value: service_id,
            label: name,
          };
        },
      );

      const servicesIds = response.data.services.map(
        ({ id: service_id }: SelectServices) => {
          return service_id;
        },
      );

      const { begin, end, costumer_id } = response.data;

      setSelectedServicesForm(servicesIds);
      setIdCostumer(costumer_id);
      setSelectedServices(serviceOptions);
      setEndDate(new Date(end));

      const beginUTC = begin;
      const beginLocal = new Date(beginUTC);
      // const beginLocalAjustada = new Date(
      //   beginLocal.getTime() + beginLocal.getTimezoneOffset() * 60000,
      // );
      setStartDate(beginLocal);

      const endUTC = end;
      const endLocal = new Date(endUTC);
      // const endLocalAjustada = new Date(
      //   endLocal.getTime() + endLocal.getTimezoneOffset() * 60000,
      // );
      setEndDate(endLocal);

      return setReport(response.data);
    });
  }, [refresh]);

  useEffect(() => {
    api.get(`techVisitReport/comment/${id}`).then(response => {
      return setComments(response.data);
    });
  }, [refresh]);

  useEffect(() => {
    api
      .get("/costumers", {
        params: {
          status: "A",
        },
      })
      .then(response => {
        return setSelectCostumers(response.data);
      });
  }, [modalAddCostumer]);

  useEffect(() => {
    api
      .get("/services", {
        params: {
          status: "A",
        },
      })
      .then(response => {
        const serviceOptions = response.data.map(
          ({ id: service_id, name }: SelectServices) => {
            return {
              value: service_id,
              label: name,
            };
          },
        );

        return setSelectServices(serviceOptions);
      });

    api
      .get("/equipments", {
        params: {
          status: "A",
        },
      })
      .then(response => {
        const equipmentForm = response.data.map(
          (equipment: SelectEquipments) => {
            return {
              equipment_id: equipment.id,
              name: equipment.name,
              grade: "",
            };
          },
        );

        return setSelectEquipments(equipmentForm);
      });

    api
      .get("/users", {
        params: { status: "A" },
      })
      .then(response => {
        return setSelectTechnicians(response.data);
      });
  }, []);

  useEffect(() => {
    api
      .get("/sectors", {
        params: {
          costumer_id: idCostumer,
          status: "A",
        },
      })
      .then(response => {
        return setSelectSectors(response.data);
      });

    api
      .get("/responsibles", {
        params: { costumer_id: idCostumer, status: "A" },
      })
      .then(response => {
        return setSelectResponsibles(response.data);
      });
  }, [idCostumer, modalAddSector]);

  async function handleSubmit({
    costumer_id,
    begin,
    end,
    observation,
    pending,
    sectors,
    services,
    responsible_id,
    technician_id,
    status,
  }: FormTechVisitReport) {
    await api.patch(`/techVisitReport/responsibles/${id}`, {
      responsible_id,
      technician_id,
    });

    const servicesFormated = services.map(service => {
      return { service_id: service };
    });

    // const sectorsFormated = sectors.map(
    //   ({
    //     sector_id,
    //     equipments,
    //     images,
    //     observation: sector_observation,
    //   }: FormSectors) => {
    //     const equipmentsFormated = equipments.map(equipment => {
    //       return {
    //         equipment_id: Object.keys(equipment)[0],
    //         grade: Object.values(equipment)[0],
    //       };
    //     });

    //     return {
    //       sector_id,
    //       equipments: equipmentsFormated,
    //       images,
    //       observation: sector_observation,
    //     };
    //   },
    // );

    const { report_sector_ids } = await api
      .put(`/techVisitReport/${id}`, {
        costumer_id,
        user_id: user.id,
        begin,
        end,
        observation,
        pending,
        services: servicesFormated,
        sectors,
        status,
      })
      .then(response => {
        return response.data;
      });

    sectors.forEach(async ({ sector_id, images }) => {
      const { report_sector_id } = await report_sector_ids.find(
        element => element.sector_id === sector_id,
      );

      await images.forEach(file => {
        const data = new FormData();

        data.append("files", file);

        api.post(`/techVisitReport/sector_images/${report_sector_id}`, data);
      });
    });

    return navigate(`/tech-visit-reports`);
  }

  async function deleteImage({ id: image_id }) {
    setLoading(true);
    await api.delete(`/techVisitReport/sector_images/${image_id}`);

    setTimeout(() => {
      setRefresh(!refresh);
      return setLoading(false);
    }, 2000);
  }

  async function updateImage({ file, report_sector_id }) {
    setLoading(true);
    const data = new FormData();

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const fileCompressed: any = await imageCompression(file, options);

    data.append("files", fileCompressed);

    await api.post(`/techVisitReport/sector_images/${report_sector_id}`, data);

    setTimeout(() => {
      setRefresh(!refresh);
      return setLoading(false);
    }, 2000);
  }

  function sendComment() {
    api.post(`/techVisitReport/comment/${id}`, {
      comment,
      user_id: user.id,
    });

    setComment("");
    setRefresh(!refresh);
  }

  const initialValues: FormTechVisitReport = !report
    ? {
        begin: "",
        end: "",
        costumer_id: "",
        user_id: user.id,
        services: [],
        sectors: [],
        pending: "",
        observation:
          "Os demais setores e equipamentos estavam em bom estado de conservação e(ou) funcionamento.",
        responsible_id: "",
        responsible_signature: "",
        technician_id: "",
        technician_signature: "",
        status: "",
      }
    : {
        begin: report.begin,
        end: report.end,
        costumer_id: report.costumer_id,
        user_id: report.user_id,
        services: selectedServicesForm,
        sectors: report.sectors,
        pending: report.pending,
        observation: report.observation,
        responsible_id: report.responsible_id,
        responsible_signature: report.responsible_signature,
        technician_id: report.technician_id,
        technician_signature: report.technician_signature,
        status: report.status,
      };

  return (
    <>
      {loading && <Loading />}
      <div className="h-full">
        <Header userName={user?.name} />
        <div className="md:w-auto xl:w-[400px] mx-6 my-5">
          <PageTitle
            title="Editar Relatório"
            backButton
            backPath="/tech-visit-reports"
          />
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form className="flex flex-col flex-wrap">
                <div className="">
                  {/* <button
                  type="button"
                  onClick={() => {
                    console.log(values);
                  }}
                >
                  Opa
                </button> */}
                  <p className="text-small text-white mt-2">
                    Data e hora do início da visita
                  </p>
                  <DateHourPicker
                    selected={startDate}
                    onChange={date => {
                      setStartDate(date);
                      setFieldValue(
                        "begin",
                        // moment(date).format("yyyy-MM-dd H:mm"),
                        moment(date).format(),
                      );
                    }}
                    timeInputLabel="Time:"
                    dateFormat="dd/MM/yyyy H:mm"
                    showTimeInput
                    error={errors.begin}
                  />
                </div>
                <div>
                  <p className="text-small text-white mt-2">Cliente</p>
                  <div className="flex gap-2">
                    <SelectSingle
                      name="costumer_id"
                      onChange={e => {
                        setFieldValue("costumer_id", e.target.value);
                        setIdCostumer(e.target.value);
                      }}
                    >
                      <option value="" selected disabled>
                        Selecione um cliente
                      </option>
                      {selectCostumers &&
                        selectCostumers.map(
                          ({ id: costumerId, name }: SelectCostumers) => (
                            <option key={costumerId} value={costumerId}>
                              {name}
                            </option>
                          ),
                        )}
                    </SelectSingle>
                    <button
                      type="button"
                      className="flex items-center justify-center h-[52px] w-10 text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                      onClick={() => setModalAddCostumer(true)}
                    >
                      <AiOutlinePlus size={16} />
                    </button>
                    {modalAddCostumer && (
                      <div
                        className={
                          !modalAddCostumer ? "hidden" : "relative z-10"
                        }
                        aria-labelledby="modal-title"
                        role="dialog"
                        aria-modal="true"
                      >
                        <ModalAddCostumer
                          setModalAddCostumer={setModalAddCostumer}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col">
                  <p className="text-small text-white mt-2">Serviços</p>
                  <SelectMultiple
                    name="services"
                    options={selectServices}
                    value={selectedServices}
                    isMulti
                    onChange={option => {
                      setSelectedServicesForm(option.map(item => item.value));
                      setSelectedServices(option.map(item => item));
                    }}
                    error={errors.services}
                  />
                </div>
                <div>
                  <h1 className="text-xl text-white font-medium mt-2">
                    Setores
                  </h1>
                  <FieldArray
                    name="sectors"
                    render={arraySectors => (
                      <>
                        {values.sectors && values.sectors.length > 0 ? (
                          values.sectors.map(
                            ({ show, images }: FormSectors, indexSector) => (
                              <div className="h-fit mb-2">
                                <div className="grid grid-cols-2">
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        arraySectors.remove(indexSector)
                                      }
                                      className="relative top-[10px] right-[10px] p-1 bg-red-500 rounded-full"
                                    >
                                      <MdOutlineDelete
                                        size={20}
                                        className="text-white"
                                      />
                                    </button>
                                  </div>
                                  <div className="flex items-center justify-start">
                                    {show === true ? (
                                      <button
                                        type="button"
                                        className="relative top-[10px] right-[14px] p-1 bg-white rounded-full"
                                        onClick={() =>
                                          setFieldValue(
                                            `sectors[${indexSector}].show`,
                                            false,
                                          )
                                        }
                                      >
                                        <IoIosArrowUp size={20} />
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="relative top-[10px] right-[14px] p-1 bg-white rounded-full"
                                        onClick={() =>
                                          setFieldValue(
                                            `sectors[${indexSector}].show`,
                                            true,
                                          )
                                        }
                                      >
                                        <IoIosArrowDown size={20} />
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="bg-[#0085BD] w-full rounded-lg p-4 mt-[-9px]">
                                  <p className="text-small text-white">Setor</p>
                                  <div className="flex gap-2">
                                    <Field
                                      as="select"
                                      name={`sectors[${indexSector}].sector_id`}
                                      className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none"
                                    >
                                      <option value="" defaultChecked disabled>
                                        Selecione um setor
                                      </option>
                                      {selectSectors &&
                                        selectSectors.map(
                                          ({
                                            id: sectorId,
                                            name,
                                          }: SelectSectors) => (
                                            <option
                                              key={sectorId}
                                              value={sectorId}
                                            >
                                              {name}
                                            </option>
                                          ),
                                        )}
                                    </Field>
                                    <button
                                      type="button"
                                      className="flex items-center justify-center min-h-[56px] w-10 text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                                      onClick={() => setModalAddSector(true)}
                                    >
                                      <AiOutlinePlus size={16} />
                                    </button>
                                    {modalAddSector && (
                                      <div
                                        className={
                                          !modalAddSector
                                            ? "hidden"
                                            : "relative z-10"
                                        }
                                        aria-labelledby="modal-title"
                                        role="dialog"
                                        aria-modal="true"
                                      >
                                        <ModalAddSector
                                          setModalAddSector={setModalAddSector}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {values.sectors[indexSector].show ===
                                    true && (
                                    <>
                                      <div className="mt-2 grid grid-cols-custom-5 text-white">
                                        <div>
                                          <p className="font-medium">
                                            Equipamentos
                                          </p>
                                        </div>
                                        <div>
                                          <p className="font-medium">O</p>
                                        </div>
                                        <div>
                                          <p className="font-medium">B</p>
                                        </div>
                                        <div>
                                          <p className="font-medium">R</p>
                                        </div>
                                        <div>
                                          <p className="font-medium" />
                                        </div>
                                        {values.sectors[
                                          indexSector
                                        ].equipments.map(
                                          (
                                            { equipment_id, equipment },
                                            indexEquipment,
                                          ) => (
                                            <>
                                              <div>{equipment.name}</div>
                                              <div>
                                                <Field
                                                  type="radio"
                                                  value="O"
                                                  name={`sectors[${indexSector}].equipments[${indexEquipment}].grade`}
                                                />
                                              </div>
                                              <div>
                                                <Field
                                                  type="radio"
                                                  value="B"
                                                  name={`sectors[${indexSector}].equipments[${indexEquipment}].grade`}
                                                />
                                              </div>
                                              <div>
                                                <Field
                                                  type="radio"
                                                  value="R"
                                                  name={`sectors[${indexSector}].equipments[${indexEquipment}].grade`}
                                                />
                                              </div>
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    setFieldValue(
                                                      `sectors[${indexSector}].equipments[${indexEquipment}].grade`,
                                                      "",
                                                    );
                                                  }}
                                                >
                                                  <MdOutlineDelete />
                                                </button>
                                              </div>
                                            </>
                                          ),
                                        )}
                                      </div>
                                      <p className="text-small font-medium text-white mt-2">
                                        Observações
                                      </p>
                                      <Field
                                        as="textarea"
                                        placeholder="..."
                                        name={`sectors[${indexSector}].observation`}
                                        className="h-[104px] p-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none resize-none scrollbar-thumb-[#0085BD] scrollbar-track-transparent scrollbar-thin"
                                      />
                                      {/* {images ? ( */}
                                      <div className="flex flex-row flex-wrap gap-1">
                                        <FieldArray
                                          name={`sectors[${indexSector}].images`}
                                          render={arrayImages => (
                                            <>
                                              {images.map(
                                                (file, indexImage) => {
                                                  return (
                                                    <div className="flex flex-col gap-1">
                                                      {file.file_name !==
                                                      undefined ? (
                                                        <img
                                                          className="w-16 h-12 overflow-hidden bg-white rounded-lg border-dashed border-2 border-[#0085BD] flex items-center justify-center"
                                                          src={`https://app-luvilimp.s3.amazonaws.com/techVisitReportSectorImages/${file.file_name}`}
                                                          alt=""
                                                        />
                                                      ) : (
                                                        <label
                                                          htmlFor={`sectors[${indexSector}].image[${indexImage}]`}
                                                          className="w-16 h-12 overflow-hidden bg-white rounded-lg border-dashed border-2 border-[#0085BD] flex items-center justify-center"
                                                        >
                                                          <BsCloudArrowUp className="text-[#0085BD]" />
                                                          <input
                                                            type="file"
                                                            id={`sectors[${indexSector}].image[${indexImage}]`}
                                                            className="hidden"
                                                            onChange={e => {
                                                              updateImage({
                                                                report_sector_id:
                                                                  values
                                                                    .sectors[
                                                                    indexSector
                                                                  ].id,
                                                                file: e.target
                                                                  .files[0],
                                                              });
                                                            }}
                                                          />
                                                        </label>
                                                      )}
                                                      <FiTrash
                                                        className="bg-white text-[#0085BD] rounded-lg p-1 text-xl cursor-pointer w-full"
                                                        onClick={() => {
                                                          if (
                                                            id === undefined
                                                          ) {
                                                            // values.sectors[
                                                            //   indexSector
                                                            // ].images.splice(
                                                            //   indexImage,
                                                            //   1,
                                                            // );
                                                            arrayImages.remove(
                                                              indexImage,
                                                            );
                                                          } else {
                                                            deleteImage({
                                                              id: file.id,
                                                            });
                                                          }
                                                        }}
                                                      />
                                                    </div>
                                                  );
                                                },
                                              )}
                                              <button
                                                type="button"
                                                onClick={() => {
                                                  arrayImages.push({});
                                                }}
                                              >
                                                <FiPlus className="h-full bg-white text-[#0085BD] rounded-lg p-1 text-xl cursor-pointer" />
                                              </button>
                                            </>
                                          )}
                                        />
                                      </div>
                                      {/* ) : (
                                      <aside className="flex flex-row flex-wrap">
                                        <UploadComponent
                                          setFieldValue={setFieldValue}
                                          indexSector={indexSector}
                                        />
                                        {values.sectors[indexSector].images &&
                                          values.sectors[
                                            indexSector
                                          ].images.map(file => (
                                            <div
                                              className="w-16 h-12 ml-1 mt-1 overflow-hidden bg-white rounded-lg border-dashed border-2 border-[#0085BD] flex items-center justify-center"
                                              key={file.name}
                                            >
                                              <div className="flex min-w-0 overflow-hidden">
                                                <img
                                                  alt="Imagem do setor"
                                                  src={
                                                    !file.file_name
                                                      ? file.preview
                                                      : `https://app-luvilimp.s3.amazonaws.com/techVisitReportSectorImages/${file.file_name}`
                                                  }
                                                  className="block w-auto h-auto"
                                                  onLoad={() => {
                                                    URL.revokeObjectURL(
                                                      file.preview,
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          ))}
                                      </aside>
                                    )} */}
                                    </>
                                  )}
                                </div>
                              </div>
                            ),
                          )
                        ) : (
                          <div />
                        )}
                        <div className="w-10 h-8 mt-1 bg-[#0085BD] rounded-lg">
                          <button
                            type="button"
                            onClick={() =>
                              arraySectors.push({
                                sector_id: "",
                                equipments: selectEquipments,
                                observation: "",
                                show: true,
                              })
                            }
                            className="flex items-center justify-center w-full h-full"
                          >
                            <FiPlus size={20} className="text-white" />
                          </button>
                        </div>
                      </>
                    )}
                  />
                </div>
                <p className="text-small text-white mt-2">
                  Ficou alguma pendência?
                </p>
                <SelectSingle
                  name="pending"
                  defaultValue=""
                  onChange={e => setFieldValue("pending", e.target.value)}
                >
                  <option value="" disabled>
                    Sim ou Não
                  </option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </SelectSingle>
                <p className="text-small text-white mt-2">Notas</p>
                <Field
                  as="textarea"
                  placeholder="Observações gerais"
                  name="observation"
                  className="h-[104px] p-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none resize-none scrollbar-thumb-[#0085BD] scrollbar-track-transparent scrollbar-thin focus:ring-0 border-none"
                />
                <p className="text-small text-white mt-2">
                  Data e hora do término da visita
                </p>
                <DateHourPicker
                  selected={endDate}
                  onChange={date => {
                    setEndDate(date);
                    setFieldValue(
                      "end",
                      // moment(date).format("yyyy-MM-dd H:mm"),
                      moment(date).format(),
                    );
                  }}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy H:mm"
                  showTimeInput
                  error={errors.end}
                />
                <p className="text-small text-white mt-2">
                  Responsável Técnico
                </p>
                <Field
                  as="select"
                  name="technician_id"
                  className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
                >
                  <option value="" defaultChecked disabled>
                    Selecione o responsável
                  </option>
                  {selectTechnicians &&
                    selectTechnicians.map(({ id: technician_id, name }) => (
                      <option value={technician_id}>{name}</option>
                    ))}
                </Field>
                {report && report.technician_signature && (
                  <>
                    <p className="text-small text-white mt-2">
                      Assinatura Técnico
                    </p>
                    <img
                      className="bg-white rounded-lg p-6"
                      src={`https://app-luvilimp.s3.amazonaws.com/techVisitReportSignatureImages/${report.technician_signature}`}
                      alt="Assinatura do Técnico"
                    />
                  </>
                )}
                <p className="text-small text-white mt-2">
                  Responsável Cliente
                </p>
                <Field
                  as="select"
                  name="responsible_id"
                  className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
                >
                  <option value="" defaultChecked disabled>
                    Selecione o responsável
                  </option>
                  {selectResponsibles &&
                    selectResponsibles.map(
                      ({ id: responsible_id, name: responsible_name }) => (
                        <option value={responsible_id}>
                          {responsible_name}
                        </option>
                      ),
                    )}
                </Field>
                {report && report.responsible_signature && (
                  <>
                    <p className="text-small text-white mt-2">
                      Assinatura Cliente
                    </p>
                    <img
                      className="bg-white rounded-lg p-6"
                      src={`https://app-luvilimp.s3.amazonaws.com/techVisitReportSignatureImages/${report.responsible_signature}`}
                      alt="Assinatura do Responsável"
                    />
                  </>
                )}
                <FormButton title="Salvar" type="submit" />
              </Form>
            )}
          </Formik>
          <h1 className="text-xl text-white font-medium mt-2">Comentários</h1>
          <div className="flex flex-col mt-2 text-white bg-[#0085BD] p-4 rounded-lg gap-2">
            {comments ? (
              comments.map(
                ({
                  id: comment_id,
                  user: author_comment,
                  comment: user_comment,
                  created_at,
                }: Comments) => (
                  <>
                    <div key={comment_id} className="flex gap-1 items-center">
                      <span className="text-md font-medium">
                        {author_comment.name}
                      </span>
                      <span className="text-xs rounded-lg">
                        {moment(created_at).format("DD/MM/YYYY - H:mm")}
                      </span>
                    </div>
                    <span className="bg-white text-[#7FA5B2] py-2 px-3 rounded-lg">
                      {user_comment}
                    </span>
                  </>
                ),
              )
            ) : (
              <div>Nenhum comentário encontrado!</div>
            )}
          </div>
          <div>
            <p className="text-small text-white mt-2">Escreva seu comentário</p>
            <textarea
              placeholder="..."
              name="comment"
              value={comment}
              onChange={e => setComment(e.target.value)}
              className="h-[104px] p-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none resize-none scrollbar-thumb-[#0085BD] scrollbar-track-transparent scrollbar-thin focus:ring-0 border-none"
            />
            <FormButton title="Comentar" onClick={() => sendComment()} />
          </div>
        </div>
      </div>
    </>
  );
}

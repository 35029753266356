import { Link } from "react-router-dom";
import { IoArrowUndoSharp } from "react-icons/io5";

interface PageTitleProps {
  title: string;
  backButton: boolean;
  backPath: string;
}

export function PageTitle({ title, backButton, backPath }: PageTitleProps) {
  return (
    <div className="flex flex-row items-center justify-between">
      <h1 className="text-xl text-white font-medium">{title}</h1>
      {backButton ? (
        <Link
          to={`${backPath}`}
          className="text-sm text-white underline underline-offset-2"
        >
          <IoArrowUndoSharp size={30} className="text-xl" />
        </Link>
      ) : (
        ""
      )}
    </div>
  );
}

import { Routes as Router, Route } from "react-router-dom";
import { PrivateRoute } from "./Route";

import { SignIn } from "../pages/SignIn";
import { ResetPassword } from "../pages/ResetPassword";
import { Dashboard } from "../pages/Dashboard";
import { Costumers } from "../pages/Costumers";
import { Equipments } from "../pages/Equipments";
import { Services } from "../pages/Services";
import { Sectors } from "../pages/Sectors";
import { Users } from "../pages/Users";
import { Responsibles } from "../pages/Responsibles";

import { EquipmentEdit } from "../pages/Equipments/EquipmentEdit/EquipmentEdit";
import { SectorEdit } from "../pages/Sectors/SectorEdit/SectorEdit";
import { CostumerEdit } from "../pages/Costumers/CostumerEdit/CostumerEdit";
import { UserEdit } from "../pages/Users/UserEdit/UserEdit";
import { ServiceEdit } from "../pages/Services/ServiceEdit/ServiceEdit";
import { ResponsibleEdit } from "../pages/Responsibles/ResponsibleEdit/ResponsibleEdit";
import { TechVisitReport } from "../pages/TechVisitReport";
import { TechVisitReportCreate } from "../pages/TechVisitReport/TechVisitReportForms/TechVisitReportCreate";
import { TestPage } from "../pages/TestPage";
import { TechVisitReportList } from "../pages/TechVisitReport/TechVisitReportList/TechVisitReportList";
import { TechVisitReportConfirm } from "../pages/TechVisitReport/TechVisitReportForms/TechVisitReportConfirm";
import { TechVisitReportEdit } from "../pages/TechVisitReport/TechVisitReportForms/TechVisitReportEdit";
import { TechVisitReportPDF } from "../pages/TechVisitReport/TechVisitReportPDF/TechVisitReportPDF";
import { CostumersMonitoring } from "../pages/CostumersMonitoring";

export function Routes(): JSX.Element {
  return (
    <Router>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
      <Route path="/tests" element={<TestPage />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route path="/costumers">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Costumers />
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <CostumerEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <CostumerEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="monitoring"
          element={
            <PrivateRoute>
              <CostumersMonitoring />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/equipments">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Equipments />
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <EquipmentEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <EquipmentEdit />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/services">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Services />
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <ServiceEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <ServiceEdit />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/sectors">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Sectors />
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <SectorEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <SectorEdit />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/responsibles">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Responsibles />
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <ResponsibleEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <ResponsibleEdit />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/users">
        <Route
          path=""
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <UserEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <UserEdit />
            </PrivateRoute>
          }
        />
      </Route>

      <Route path="/tech-visit-reports">
        <Route
          path=""
          element={
            <PrivateRoute>
              <TechVisitReport />
            </PrivateRoute>
          }
        />
        <Route
          path="new"
          element={
            <PrivateRoute>
              <TechVisitReportCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="new/:id"
          element={
            <PrivateRoute>
              <TechVisitReportCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="confirm/:id"
          element={
            <PrivateRoute>
              <TechVisitReportConfirm />
            </PrivateRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <PrivateRoute>
              <TechVisitReportEdit />
            </PrivateRoute>
          }
        />
        <Route path="pdf/:id" element={<TechVisitReportPDF />} />
      </Route>
    </Router>
  );
}

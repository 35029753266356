/* eslint-disable import/no-extraneous-dependencies */
import { useNavigate, Link } from "react-router-dom";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTable, usePagination } from "react-table";

import { MdCancel, MdCheck, MdOutlinePictureAsPdf } from "react-icons/md";
import { Header } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { AuthContext } from "../../contexts/AuthContext";
import { api } from "../../services/api";
import { Loading } from "../../components/Loading";

interface ListCostumerMonitoring {
  report_id: string;
  customer_name: string;
  services: string;
  last_report_date: string;
  alert_days_without_report: number;
  days_without_report: number;
  alert: boolean;
}

interface BigNumbersMonitoring {
  total_true: number;
  total_false: number;
}

export function CostumersMonitoring() {
  const { user } = useContext(AuthContext);

  const [listCostumerMonitoring, setListCostumerMonitoring] = useState<
    ListCostumerMonitoring[]
  >([]);
  const [bigNumbersMonitoring, setBigNumbersMonitoring] =
    useState<BigNumbersMonitoring>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .post("/costumers/monitoring")
      .then(response => {
        setListCostumerMonitoring(response.data.costumers);
        setBigNumbersMonitoring(response.data.bigNumbers);
        setLoading(false);
      })
      .catch(() => {
        // navigate("/signin");
        setLoading(false);
      });
  }, [navigate]);

  const columns = useMemo(
    () => [
      {
        Header: "Relatório",
        accessor: "report_id",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ value }: { value: string }) => {
          return (
            <div className="flex justify-center">
              <Link to={`/tech-visit-reports/pdf/${value}`} target="_blank">
                <MdOutlinePictureAsPdf size={22} />
              </Link>
            </div>
          );
        },
      },
      {
        Header: "Cliente",
        accessor: "customer_name",
      },
      {
        Header: "Serviços",
        accessor: "services",
      },
      {
        Header: "Data Último Relatório",
        accessor: "last_report_date",
        Cell: ({ value }: { value: string }) => {
          return new Date(value).toLocaleDateString("pt-BR");
        },
      },
      {
        Header: "Dias sem Relatório",
        accessor: "days_without_report",
      },
      {
        Header: "Dias limites sem Relatório",
        accessor: "alert_days_without_report",
      },
      {
        Header: "Situação",
        accessor: "alert",
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({ row }: { row: any }) => {
          return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {row.values.alert === true ? (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                  NOK
                </span>
              ) : (
                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  OK
                </span>
              )}
            </>
          );
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex },
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    { columns, data: listCostumerMonitoring, initialState: { pageIndex: 0 } },
    usePagination,
  );

  useEffect(() => {
    setPageSize(1000);
  }, [setPageSize]);

  return (
    <>
      {loading && <Loading />}
      <div className="h-screen">
        <Header userName={user?.name} />
        <div className="mx-6 mt-5">
          <PageTitle
            title="Monitoramento de Clientes"
            backButton
            backPath="/"
          />
          <div className="bg-white rounded-lg mb-3 mt-3 px-7 py-2 shadow-md">
            <div className="mt-3">
              <div className="grid grid-cols-1 lg:grid-cols-10 gap-3 mt-2">
                <div className="col-span-2">
                  <div className="px-3.5 py-5 bg-white rounded-lg border border-neutral-300 justify-start items-center gap-2.5 flex">
                    <div className="mr-2 min-w-[48px] max-w-[48px]">
                      <MdCheck size={32} className="text-green-500" />
                    </div>
                    <div className="w-[90%] overflow-hidden text-ellipsis whitespace-nowrap">
                      <span className="text-gray-600 font-regular text-sm flex justify-start items-center gap-3">
                        Total OK
                      </span>
                      <span className="text-gray-600 font-semibold text-base">
                        {bigNumbersMonitoring?.total_false}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-span-2">
                  <div className="px-3.5 py-5 bg-white rounded-lg border border-neutral-300 justify-start items-center gap-2.5 flex">
                    <div className="mr-2 min-w-[48px] max-w-[48px]">
                      <MdCancel size={32} className="text-red-500" />
                    </div>
                    <div className="w-[90%] overflow-hidden text-ellipsis whitespace-nowrap">
                      <span className="text-gray-600 font-regular text-sm flex justify-start items-center gap-3">
                        Total NOK
                      </span>
                      <span className="text-gray-600 font-semibold text-base">
                        {bigNumbersMonitoring?.total_true}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="flex justify-between items-center mt-7 mb-4">
              <div>
                <h3 className="text-gray-600 font-semibold text-xl">
                  Resultado de Monitoramento de Clientes
                </h3>
                <p className="text-gray-400 font-medium text-sm">
                  Total de x registros retornados
                </p>
              </div>
            </div> */}
              <div className="overflow-x-auto sm:rounded-lg mt-4">
                <table
                  {...getTableProps()}
                  className="min-w-full divide-y divide-gray-200"
                >
                  <thead className="bg-gray-50">
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th
                            {...column.getHeaderProps()}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 text-center uppercase tracking-wider"
                          >
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody
                    {...getTableBodyProps()}
                    className="bg-white divide-y divide-gray-200"
                  >
                    {page.map(row => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map(cell => (
                            <td
                              {...cell.getCellProps()}
                              className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600 text-center"
                            >
                              {cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import { Form, Formik } from "formik";
import { AiOutlineClose } from "react-icons/ai";
import { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { Link } from "react-router-dom";
import { api } from "../../../services/api";
import { SelectMultiple } from "../../../components/SelectMultiple";
import logoImg from "../../../assets/logo-completo.png";
import { Input } from "../../../components/Input";

interface ModalSendMailProps {
  showModalSendMail: boolean;
  onHide(showModalSendMail: boolean): void;
  costumer_id: string;
  report_id: string;
}

interface ISendMail {
  to: [];
  subject: string;
}

interface Services {
  service_id: string;
  name: string;
}

interface Equipments {
  equipment_id: string;
  grade: string;
  equipment: Equipment;
}

interface Equipment {
  name: string;
}

interface Sector {
  name: string;
}

interface Sectors {
  sector_id: string;
  sector: Sector;
  name: string;
  equipments: Equipments[];
  observation: string;
  images: any;
  show: boolean;
}

interface Costumer {
  id: string;
  name: string;
  address: string;
  number: string;
}

interface TechVisitReport {
  costumer: Costumer;
  begin: string;
  end: string;
  observation: string;
  code: number;
  pending: boolean;
  responsible_signature: string;
  technician_signature: string;
  sectors: Sectors[];
  services: Services[];
  technician: Technician;
  responsible: Responsible;
}

interface Responsible {
  id: string;
  name: string;
}

interface Technician {
  id: string;
  name: string;
}

function gradeWord(grade) {
  let word = "";

  switch (grade) {
    case "O":
      word = "Ótimo";
      break;
    case "B":
      word = "Bom";
      break;
    case "R":
      word = "Ruim";
      break;
    default:
      word = "";
      break;
  }

  return word;
}

export function ModalSendMail({
  showModalSendMail,
  onHide,
  costumer_id,
  report_id,
}: ModalSendMailProps) {
  const [selectResponsibles, setSelectResponsibles] = useState([]);
  const [selectedResponsibles, setSelectedResponsibles] = useState([]);
  const [report, setReport] = useState<TechVisitReport>();

  const ref = useRef();

  const initialValues: ISendMail = {
    to: [],
    subject: "",
  };

  const [prevShowModalSendMail, setPrevShowModalSendMail] = useState(false);

  useEffect(() => {
    if (showModalSendMail && !prevShowModalSendMail) {
      api
        .get("/responsibles", {
          params: {
            costumer_id,
          },
        })
        .then(response => {
          const responsibleOptions = response.data.map(({ email, name }) => {
            return {
              value: email,
              label: name,
            };
          });

          setSelectResponsibles(responsibleOptions);
        });
    }

    api.get(`/techVisitReport/${report_id}`).then(response => {
      response.data.sectors.map(sector => {
        return Object.assign(sector, { show: false });
      });

      return setReport(response.data);
    });

    setPrevShowModalSendMail(showModalSendMail);
  }, [showModalSendMail, prevShowModalSendMail, costumer_id]);

  function handleSendEmail(values: ISendMail) {
    const concatenatedEmails = values.to.join(", ");

    api.post("/techVisitReport/email/send", {
      to: concatenatedEmails,
      subject: values.subject,
      body: `Segue o relatório de Visita Técnica https://www.luvi.app/tech-visit-reports/pdf/${report_id}`,
    });
  }

  return (
    <div className={!showModalSendMail ? "hidden" : "relative z-10"}>
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      <div className="fixed inset-0 overflow-auto">
        <div className="flex items-center justify-center min-h-full p-0">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values: ISendMail) => {
              handleSendEmail(values);
            }}
          >
            {({ errors, setFieldValue, values }) => (
              <Form className="">
                <div className="relative bg-[#77C5E5] rounded-lg p-4 shadow-xl transform transition-all mx-4">
                  <div className="flex gap-2 justify-between items-center border-b pb-2 mb-4">
                    <h1 className="text-2xl font-bold text-white">
                      Enviar Relatório E-mail
                    </h1>
                    <button type="button" onClick={() => onHide(false)}>
                      <AiOutlineClose className="text-white" />
                    </button>
                  </div>
                  <div className="flex flex-col gap-4">
                    <div>
                      <span className="text-sm text-white">
                        E-mail Responsáveis
                      </span>
                      <SelectMultiple
                        className="text-gray-600"
                        name="to"
                        options={selectResponsibles}
                        value={selectedResponsibles}
                        isMulti
                        onChange={option => {
                          setSelectedResponsibles(option.map(item => item));
                          setFieldValue(
                            "to",
                            option.map(item => item.value),
                          );
                        }}
                        error={errors.to}
                      />
                    </div>
                    <div>
                      <Input
                        type="text"
                        name="subject"
                        placeholder="Assunto"
                        error={errors.subject}
                      />
                    </div>
                  </div>
                  <div className="flex space-x-2 justify-start mt-6">
                    <button
                      type="button"
                      onClick={() => onHide(false)}
                      className="w-full min-h-[56px] text-sm text-white rounded-lg bg-red-500 hover:bg-red-700 transition-colors"
                    >
                      <span className="font-medium">Fechar</span>
                    </button>
                    <button
                      type="submit"
                      className="w-full min-h-[56px] text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                      onClick={() => {
                        onHide(false);
                      }}
                    >
                      <span className="font-medium">Enviar</span>
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

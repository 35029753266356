import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { api } from "../../services/api";

import { Header } from "../../components/Header";
import { ItemPagination } from "../../components/ItemPagination";
import { AuthContext } from "../../contexts/AuthContext";
import { PageTitle } from "../../components/PageTitle";

interface Sector {
  id: string;
  name: string;
  status: string;
  costumer: Costumer;
}

interface Costumer {
  id: string;
  name: string;
}

export function Sectors() {
  const { user } = useContext(AuthContext);
  const [sectors, setSectors] = useState<Sector[]>([]);
  const [costumers, setCostumers] = useState<Costumer[]>([]);
  const [costumer_id, setCostumerId] = useState("");
  const [changeStatus, setChangeStatus] = useState<number>(0);

  useEffect(() => {
    api.get("/sectors").then(response => {
      return setSectors(response.data);
    });

    api.get("/costumers").then(response => {
      return setCostumers(response.data);
    });
  }, []);

  useEffect(() => {
    api
      .get("/sectors", {
        params: {
          costumer_id,
        },
      })
      .then(response => {
        return setSectors(response.data);
      });
  }, [costumer_id, changeStatus]);

  async function handleChangeStatus(id: string, newStatus: string) {
    api.patch(`sectors/status/${id}/${newStatus === "A" ? "I" : "A"}`);
    setTimeout(() => {
      setChangeStatus(changeStatus + 1);
    }, 1000);
  }

  return (
    <div className="pb-6">
      <Header userName={user?.name} />
      <div className="md:w-auto xl:w-[400px] mx-6 mt-5">
        <PageTitle title="Setores" backButton backPath="/" />
        <div className="w-[52px] h-[52px] mt-1 flex items-center justify-center bg-[#0085BD] rounded-lg">
          <Link to="/sectors/new">
            <FiPlus size={32} className="text-white" />
          </Link>
        </div>
        <div className="mt-1">
          <span className="text-small text-white">Cliente</span>
          <select
            name="costumer_id"
            onChange={e => setCostumerId(e.target.value)}
            className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
          >
            <option value="" defaultChecked>
              Todos
            </option>
            {costumers &&
              costumers.map(({ id: costumerId, name }: Costumer) => (
                <option key={costumerId} value={costumerId}>
                  {name}
                </option>
              ))}
          </select>
        </div>
        {sectors &&
          sectors.map(({ id, name, costumer, status }: Sector) => (
            <ItemPagination
              key={id}
              title={name}
              description={costumer.name}
              id={id}
              status={status}
              hasDelete
              handleChangeStatus={() => handleChangeStatus(id, status)}
            />
          ))}
      </div>
    </div>
  );
}

import { Field } from "formik";
import { SelectHTMLAttributes } from "react";

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  error?: string;
}

function getClasses(error) {
  return error
    ? {
        field:
          "h-[52px] px-4 w-full rounded-lg text-red-500 border-2 border-red-500 bg-white focus:outline-none focus:ring-0",
      }
    : {
        field:
          "h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none",
      };
}

export function SelectSingle({ error, children, ...rest }: SelectProps) {
  return (
    <div className="w-full flex flex-col">
      <Field as="select" className={getClasses(error).field} {...rest}>
        {children}
      </Field>
      <div className="text-xs text-red-500">{error}</div>
    </div>
  );
}

import { useContext } from "react";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header";
import { PageTitle } from "../../components/PageTitle";
import { AuthContext } from "../../contexts/AuthContext";
import { TechVisitReportList } from "./TechVisitReportList/TechVisitReportList";

export function TechVisitReport() {
  const { user } = useContext(AuthContext);

  return (
    <div className="h-screen">
      <Header userName={user?.name} />
      <div className="md:w-auto xl:w-[400px] mx-6 mt-5">
        <PageTitle
          title="Relatórios de Visita Técnica"
          backButton
          backPath="/"
        />
        <div className="w-[52px] h-[52px] mt-1 flex items-center justify-center bg-[#0085BD] rounded-lg">
          <Link to="/tech-visit-reports/new">
            <FiPlus size={32} className="text-white" />
          </Link>
        </div>
        {/* {!!user.isAdmin && <TechVisitReportList />} */}
        <TechVisitReportList />
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";

import { useContext, useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { ItemPagination } from "../../components/ItemPagination";
import { api } from "../../services/api";
import { AuthContext } from "../../contexts/AuthContext";
import { PageTitle } from "../../components/PageTitle";

interface User {
  id: string;
  name: string;
  occupation: string;
  status: string;
}

export function Users() {
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState<User[]>([]);
  const [changeStatus, setChangeStatus] = useState<number>(0);

  useEffect(() => {
    api.get("/users").then(response => {
      return setUsers(response.data);
    });
  }, [changeStatus]);

  async function handleChangeStatus(id: string, newStatus: string) {
    api.patch(`users/status/${id}/${newStatus === "A" ? "I" : "A"}`);
    setTimeout(() => {
      setChangeStatus(changeStatus + 1);
    }, 1000);
  }

  return (
    <div className="h-screen">
      <Header userName={user?.name} />
      <div className="md:w-auto xl:w-[400px] mx-6 mt-5">
        <PageTitle title="Usuários" backButton backPath="/" />
        <div className="w-[52px] h-[52px] mt-1 flex items-center justify-center bg-[#0085BD] rounded-lg">
          <Link to="/users/new">
            <FiPlus size={32} className="text-white" />
          </Link>
        </div>
        {users &&
          users.map(({ id, name, occupation, status }: User) => (
            <ItemPagination
              key={id}
              title={name}
              id={id}
              status={status}
              description={occupation}
              hasDelete
              handleChangeStatus={() => handleChangeStatus(id, status)}
            />
          ))}
      </div>
    </div>
  );
}

import { AiOutlineClose } from "react-icons/ai";
import { api } from "../services/api";

interface ModalProps {
  showModal: boolean;
  onHide(setModal: boolean): void;
  statusUpdated(): void;
  id: string;
  status: string;
}

export function ModalUpdateStatusTechVisitReport({
  showModal,
  onHide,
  statusUpdated,
  status,
  id,
}: ModalProps) {
  function updateStatusTechVisitReport() {
    api.patch(`/techVisitReport/status/${id}/${status}`).then(() => {
      onHide(false);
      statusUpdated();
    });
  }

  return (
    <div className={!showModal ? "hidden" : "relative z-10"}>
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      <div className="fixed inset-0 overflow-auto text-white">
        <div className="flex items-center justify-center min-h-full p-0">
          <div className="relative bg-[#77C5E5] rounded-lg p-4 shadow-xl transform transition-all">
            <div className="flex justify-between items-center border-b pb-2 mb-4">
              <h1 className="text-2xl font-bold">Alterar Status</h1>
              <button type="button" onClick={() => onHide(false)}>
                <AiOutlineClose />
              </button>
            </div>
            <div className="flex flex-col">
              <div>
                <span className="text-sm text-white font-medium">
                  Tem certeza que deseja{" "}
                  {status === "A" ? <>ativar</> : <>inativar</>} o relatório?
                </span>
              </div>
            </div>
            <div className="flex space-x-2 justify-start mt-6">
              <button
                onClick={() => onHide(false)}
                className="w-full min-h-[56px] text-sm text-white rounded-lg bg-red-500 hover:bg-red-700 transition-colors"
              >
                <span className="font-medium">Fechar</span>
              </button>
              <button
                type="button"
                className="w-full min-h-[56px] text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                onClick={() => updateStatusTechVisitReport()}
              >
                <span className="font-medium">
                  {status === "A" ? <>Ativar</> : <>Inativar</>}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/* eslint-disable import/no-extraneous-dependencies */
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import { Header } from "../../../components/Header";
import { FormButton } from "../../../components/FormButton";
import { Input } from "../../../components/Input";
import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { PageTitle } from "../../../components/PageTitle";

interface Sector {
  name: string;
  costumer_id: string;
}

interface Costumer {
  id: string;
  name: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Campo setor é obrigatório"),
});

export function SectorEdit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [sector, setSector] = useState<Sector>();
  const [costumers, setCostumers] = useState<Costumer[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      api.get(`sectors/${id}`).then(response => {
        return setSector(response.data);
      });
    }

    api.get("/costumers").then(response => {
      return setCostumers(response.data);
    });
  }, [id]);

  function handleSubmit(data: Sector) {
    if (id) {
      api
        .put(`/sectors/${id}`, data)
        .then(() => {
          enqueueSnackbar("Setor editado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    } else {
      api
        .post("/sectors", data)
        .then(() => {
          enqueueSnackbar("Setor cadastrado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    }
    return navigate("/sectors");
  }

  const initialValues: Sector = {
    name: sector ? sector.name : "",
    costumer_id: "",
  };

  return (
    <div className="h-screen">
      <Header userName={user?.name} />
      <div className="md:w-auto xl:w-[400px] mx-6 mt-5">
        <PageTitle
          title={id ? "Editar Setor" : "Novo Setor"}
          backButton
          backPath="/sectors"
        />
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          {({ errors, setFieldValue }) => (
            <Form>
              <span className="text-sm text-white">Setor</span>
              <Input
                type="text"
                name="name"
                placeholder="Setor"
                error={errors.name}
              />
              {!id && (
                <div className="mt-1">
                  <span className="text-sm text-white">Cliente</span>
                  <select
                    name="costumer_id"
                    defaultValue=""
                    onChange={e => setFieldValue("costumer_id", e.target.value)}
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
                  >
                    <option value="" disabled>
                      Selecione um cliente
                    </option>
                    {costumers &&
                      costumers.map(({ id: costumer_id, name }: Costumer) => (
                        <option key={costumer_id} value={costumer_id}>
                          {name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              <FormButton
                title={id ? "Editar Setor" : "Cadastrar Setor"}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

import { MdOutlineMail } from "react-icons/md";
import { Link } from "react-router-dom";

import logoImg from "../../assets/logo.png";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";

export function ResetPassword() {
  return (
    <div className="flex flex-col justify-center h-screen m-4 items-center">
      <img className="w-[246px]" src={logoImg} alt="Logo escrito Luvilimp" />
      <form className="w-full flex flex-col items-center">
        <h1 className="mt-14 mb-6 text-2xl font-medium  text-white">
          Redefinir senha
        </h1>
        <Input
          name="email"
          icon={MdOutlineMail}
          type="text"
          placeholder="E-mail"
        />
        <Button type="submit" title="Recuperar senha" />
        <Link className="mt-6 text-sm text-white" to="/signin">
          Entrar
        </Link>
      </form>
    </div>
  );
}

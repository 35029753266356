import { MdOutlineRemoveRedEye } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { AiOutlineCheck } from "react-icons/ai";
import { Link } from "react-router-dom";

interface ItemPaginationProps {
  id: string;
  title: string;
  description?: string;
  status?: string;
  hasDelete?: boolean;
  handleChangeStatus?(id: string, newStatus: string): Promise<void>;
}

export function ItemPagination({
  id,
  title,
  description,
  status,
  hasDelete,
  handleChangeStatus,
}: ItemPaginationProps) {
  return (
    <div className="grid grid-cols-2 mt-2 w-full rounded-lg bg-white p-4">
      <div className="grid grid-rows-1 items-center">
        <div>
          <h1 className="text-[#0085BD] font-medium">{title}</h1>
        </div>
        {description !== undefined && (
          <div>
            <span className="text-[#0085BD] text-xs">{description}</span>
          </div>
        )}
      </div>
      {hasDelete === true && (
        <div className="flex items-center justify-end">
          {status && status === "A" ? (
            <button
              type="button"
              onClick={() => handleChangeStatus(id, "I")}
              className="w-8 h-8 mr-4 flex items-center justify-center rounded-xl bg-green-600"
            >
              <AiOutlineCheck size={16} className="text-white" />
            </button>
          ) : (
            <button
              type="button"
              onClick={() => handleChangeStatus(id, "A")}
              className="w-8 h-8 mr-4 flex items-center justify-center rounded-xl bg-red-500"
            >
              <BsTrash size={16} className="text-white" />
            </button>
          )}
          <Link
            key={id}
            to={{
              pathname: `edit/${id}`,
            }}
            className="w-8 h-8 mr-4 flex items-center justify-center rounded-xl bg-[#0085BD]"
          >
            <MdOutlineRemoveRedEye size={16} className="text-white" />
          </Link>
        </div>
      )}
    </div>
  );
}

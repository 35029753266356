import { Link } from "react-router-dom";

import {
  MdContentPaste,
  MdOutlineStoreMallDirectory,
  MdOutlineLogout,
  MdOutlineGroups,
  MdContentCopy,
  MdFace,
  MdOutlineBuild,
  MdOutlineWorkOutline,
  MdOutlineMonitorHeart,
} from "react-icons/md";
import { useContext } from "react";
import { Header } from "../../components/Header";
import { AuthContext } from "../../contexts/AuthContext";
import { PageTitle } from "../../components/PageTitle";

export function Dashboard() {
  const { user } = useContext(AuthContext);

  return (
    <>
      <div className="mb-6">
        <Header userName={user?.name} />
        <div className="md:w-auto xl:w-[400px] mx-6 mt-5">
          <PageTitle title="Menu" backButton={false} backPath="/" />
          <div>
            <Link
              to="/tech-visit-reports"
              className="flex flex-row items-center w-full mt-2 min-h-[56px] text-base text-white rounded-lg bg-[#0085BD]"
            >
              <MdContentPaste size={32} className="mx-3" />
              Relatório de Visita Técnica
            </Link>
            {/* {!!user.isAdmin && ( */}
            <Link
              to="/costumers"
              className="flex flex-row items-center w-full mt-2 min-h-[56px] text-base text-white rounded-lg bg-[#0085BD]"
            >
              <MdOutlineStoreMallDirectory size={32} className="mx-3" />
              Clientes
            </Link>
            <Link
              to="/costumers/monitoring"
              className="flex flex-row items-center w-full mt-2 min-h-[56px] text-base text-white rounded-lg bg-[#0085BD]"
            >
              <MdOutlineMonitorHeart size={32} className="mx-3" />
              Monitoramento de Clientes
            </Link>
            <Link
              to="/equipments"
              className="flex flex-row items-center w-full mt-2 min-h-[56px] text-base text-white rounded-lg bg-[#0085BD]"
            >
              <MdOutlineBuild size={32} className="mx-3" />
              Equipamentos
            </Link>
            <Link
              to="/services"
              className="flex flex-row items-center w-full mt-2 min-h-[56px] text-base text-white rounded-lg bg-[#0085BD]"
            >
              <MdOutlineWorkOutline size={32} className="mx-3" />
              Serviços
            </Link>
            <Link
              to="/sectors"
              className="flex flex-row items-center w-full mt-2 min-h-[56px] text-base text-white rounded-lg bg-[#0085BD]"
            >
              <MdContentCopy size={32} className="mx-3" />
              Setores
            </Link>
            <Link
              to="/responsibles"
              className="flex flex-row items-center w-full mt-2 min-h-[56px] text-base text-white rounded-lg bg-[#0085BD]"
            >
              <MdFace size={32} className="mx-3" />
              Responsáveis
            </Link>
            <Link
              to="/users"
              className="flex flex-row items-center w-full mt-2 min-h-[56px] text-base text-white rounded-lg bg-[#0085BD]"
            >
              <MdOutlineGroups size={32} className="mx-3" />
              Usuários
            </Link>
            {/* )} */}
            <Link
              to="/signin"
              className="flex flex-row items-center w-full mt-2 min-h-[56px] text-base text-white rounded-lg bg-danger-500"
            >
              <MdOutlineLogout size={32} className="mx-3" />
              Logout
            </Link>
          </div>
        </div>
      </div>
      {/* <footer /> */}
    </>
  );
}

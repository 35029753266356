/* eslint-disable react/jsx-no-useless-fragment */
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import logoImg from "../../../assets/logo-completo.png";

interface Services {
  service_id: string;
  name: string;
}

interface Equipments {
  equipment_id: string;
  grade: string;
  equipment: Equipment;
}

interface Equipment {
  name: string;
}

interface Sector {
  name: string;
}

interface Sectors {
  sector_id: string;
  sector: Sector;
  name: string;
  equipments: Equipments[];
  observation: string;
  images: any;
  show: boolean;
}

interface Costumer {
  id: string;
  name: string;
  address: string;
  number: string;
}

interface TechVisitReport {
  costumer: Costumer;
  begin: string;
  end: string;
  observation: string;
  code: number;
  pending: boolean;
  responsible_signature: string;
  technician_signature: string;
  sectors: Sectors[];
  services: Services[];
  technician: Technician;
  responsible: Responsible;
}

interface Responsible {
  id: string;
  name: string;
}

interface Technician {
  id: string;
  name: string;
}

function gradeWord(grade) {
  let word = "";

  switch (grade) {
    case "O":
      word = "Ótimo";
      break;
    case "B":
      word = "Bom";
      break;
    case "R":
      word = "Ruim";
      break;
    default:
      word = "";
      break;
  }

  return word;
}

export function TechVisitReportPDF() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [report, setReport] = useState<TechVisitReport>();

  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/techVisitReport/${id}`).then(response => {
      response.data.sectors.map(sector => {
        return Object.assign(sector, { show: false });
      });

      return setReport(response.data);
    });
  }, []);

  const ref = useRef();

  return (
    <>
      {report && (
        <>
          <div className="my-4 mx-4 flex gap-2">
            <ReactToPrint
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => {
                return (
                  <button
                    type="button"
                    className="min-h-[56px] text-sm px-4 text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                  >
                    Baixar PDF
                  </button>
                );
              }}
              content={() => ref.current}
              documentTitle={`${report.costumer.name} - ${moment(
                report.begin,
              ).format("DD/MM/YYYY")}`}
            />
            {user && (
              <Link to="/tech-visit-reports">
                <button className="min-h-[56px] px-4 text-sm text-white rounded-lg bg-red-500 hover:bg-red-700 transition-colors">
                  Voltar
                </button>
              </Link>
            )}
          </div>
          <div className="mx-4">
            <div className="w-[210mm] bg-white p-6" ref={ref}>
              <div className="flex flex-col gap-6">
                <div className="flex items-center justify-center">
                  <img
                    className="w-[150px] object-contain"
                    src={logoImg}
                    alt="Logo escrito Luvilimp"
                  />
                </div>
                <div>
                  <div className="flex justify-center">
                    <span className="text-lg font-medium">
                      Avaliação de Equipamentos para Higienização
                    </span>
                  </div>
                  <div className="flex justify-center gap-1">
                    <span>{report.costumer.name} - </span>
                    <span>{report.costumer.address} - </span>
                    <span>{report.costumer.number} - </span>
                  </div>
                  <div className="flex justify-center gap-1">
                    <span className="text-sm">
                      {moment(report.begin).format("DD/MM/YYYY")} -
                    </span>
                    <span className="text-sm">
                      Início: {moment(report.begin).format("H:mm")} |
                    </span>
                    <span className="text-sm">
                      Término: {moment(report.end).format("H:mm")}
                    </span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <div className="flex justify-center gap-1">
                      <span className="text-sm">Serviços:</span>
                      {report.services.map(
                        ({ service_id, name: service_name }, indexService) => (
                          <span key={service_id} className="text-sm">
                            {service_name}
                            {indexService === report.services.length ? "" : ";"}
                          </span>
                        ),
                      )}
                    </div>
                    <div className="flex items-center justify-center mb-4">
                      <span className="text-sm">Código: {report.code}</span>
                    </div>
                  </div>
                </div>
              </div>
              {report.sectors.map(
                ({
                  sector_id,
                  sector,
                  equipments,
                  images,
                  observation: sector_observation,
                }) => (
                  <div key={sector_id} className="flex flex-col">
                    <div className="flex gap-1 border-b-2 border-black">
                      <span className="text-lg font-medium">Setor:</span>
                      <span className="text-lg font-medium">{sector.name}</span>
                    </div>
                    <span className="font-medium mt-2">Equipamentos</span>
                    <div className="grid grid-cols-3 gap-1">
                      {equipments.map(({ equipment, grade }) => (
                        <>
                          {grade !== "" && (
                            <span className="text-sm">
                              {equipment.name}:{" "}
                              <span className="font-medium">
                                {gradeWord(grade)}
                              </span>
                            </span>
                          )}
                        </>
                      ))}
                    </div>
                    <div className="flex flex-col mt-2">
                      {sector_observation && (
                        <>
                          <span className="font-medium">Observação:</span>
                          <span>{sector_observation}</span>
                        </>
                      )}
                    </div>
                    {images.length > 0 && (
                      <span className="font-medium">Imagens:</span>
                    )}
                    <div className="grid grid-cols-5 w-full gap-1">
                      {images.map(file => (
                        <img
                          className="w-[150px] h-[160px] object-fill"
                          src={`https://app-luvilimp.s3.amazonaws.com/techVisitReportSectorImages/${file.file_name}`}
                          alt="Imagem do Setor"
                        />
                      ))}
                    </div>
                  </div>
                ),
              )}
              <div className="flex flex-col">
                <span className="text-lg font-medium border-b-2 border-black">
                  Observações Gerais
                </span>
                <span className="mt-2">{report.observation}</span>
              </div>
              <div className="border-b-2 border-black">
                <span className="text-lg font-medium">Assinaturas</span>
              </div>
              <div className="flex justify-between w-full mt-6 px-8">
                <div className="flex flex-col">
                  {report.responsible_signature !== null && (
                    <img
                      className="w-[200px] h-[100px] object-fill"
                      src={`https://app-luvilimp.s3.amazonaws.com/techVisitReportSignatureImages/${report.responsible_signature}`}
                      alt="Assinatura"
                    />
                  )}
                  {report.responsible !== null && (
                    <div className="flex flex-col items-center justify-center">
                      <span className="mt-3">{report.responsible.name}</span>
                      <span className="text-lg font-medium">
                        Responsável Cliente
                      </span>
                    </div>
                  )}
                </div>
                {report.technician_signature !== null && (
                  <div className="flex flex-col">
                    <img
                      className="w-[200px] h-[100px]"
                      src={`https://app-luvilimp.s3.amazonaws.com/techVisitReportSignatureImages/${report.technician_signature}`}
                      alt="Assinatura"
                    />
                    <div className="flex flex-col items-center justify-center">
                      {report.technician !== null && (
                        <span className="mt-3">{report.technician.name}</span>
                      )}
                      <span className="text-lg font-medium">
                        Responsável Técnico
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

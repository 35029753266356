import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { api } from "../../services/api";

import { Header } from "../../components/Header";
import { ItemPagination } from "../../components/ItemPagination";
import { AuthContext } from "../../contexts/AuthContext";
import { PageTitle } from "../../components/PageTitle";

interface Costumer {
  id: string;
  name: string;
  status: string;
}

export function Costumers() {
  const { user } = useContext(AuthContext);
  const [costumers, setCostumers] = useState<Costumer[]>([]);
  const [changeStatus, setChangeStatus] = useState<number>(0);

  const navigate = useNavigate();

  useEffect(() => {
    api
      .get("/costumers")
      .then(response => {
        return setCostumers(response.data);
      })
      .catch(() => {
        navigate("/signin");
      });
  }, [changeStatus]);

  async function handleChangeStatus(id: string, newStatus: string) {
    api.patch(`costumers/status/${id}/${newStatus === "A" ? "I" : "A"}`);
    setTimeout(() => {
      setChangeStatus(changeStatus + 1);
    }, 1000);
  }

  return (
    <div className="h-screen">
      <Header userName={user?.name} />
      <div className="md:w-auto xl:w-[400px] mx-6 mt-5">
        <PageTitle title="Clientes" backButton backPath="/" />
        <div className="w-[52px] h-[52px] mt-1 flex items-center justify-center bg-[#0085BD] rounded-lg">
          <Link to="/costumers/new">
            <FiPlus size={32} className="text-white" />
          </Link>
        </div>
        {costumers &&
          costumers.map(({ id, name, status }: Costumer) => (
            <ItemPagination
              key={id}
              title={name}
              id={id}
              status={status}
              hasDelete
              handleChangeStatus={() => handleChangeStatus(id, status)}
            />
          ))}
      </div>
    </div>
  );
}

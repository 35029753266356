import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useContext } from "react";
import { MdOutlineMail, MdLockOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import logoImg from "../../assets/logo.png";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { AuthContext } from "../../contexts/AuthContext";

interface SignIn {
  email: string;
  password: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .email("E-mail inválido")
    .required("Campo email obrigatório"),
  password: Yup.string().required("Campo senha obrigatório"),
});

export function SignIn() {
  const { signIn } = useContext(AuthContext);

  const navigate = useNavigate();

  async function handleSubmit({ email, password }: SignIn) {
    await signIn({ email, password });

    navigate("/");
  }

  return (
    <div className="h-screen flex flex-col justify-center items-center p-4">
      <img className="w-[246px]" src={logoImg} alt="Logo escrito Luvilimp" />
      <h1 className="mt-14 mb-6 text-2xl font-medium  text-white">
        Faça seu login
      </h1>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {({ errors }) => (
          <Form className="w-[calc(100vw-2rem)] md:w-auto">
            <Input
              type="text"
              name="email"
              icon={MdOutlineMail}
              placeholder="E-mail"
              error={errors.email}
            />
            <Input
              type="password"
              name="password"
              icon={MdLockOutline}
              placeholder="Senha"
              error={errors.password}
            />
            <Button type="submit" title="Entrar" />
          </Form>
        )}
      </Formik>
      {/* <Link className="mt-6 text-sm text-white" to="/forgot-password">
        Esqueci minha senha
      </Link> */}
    </div>
  );
}

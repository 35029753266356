/* eslint-disable import/order */
/* eslint-disable no-else-return */
/* eslint-disable import/no-duplicates */
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, FieldArray, Form, Formik } from "formik";
import { MdOutlineDelete } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FiPlus, FiTrash } from "react-icons/fi";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import format from "date-fns/format";
import * as Yup from "yup";
import { AiOutlinePlus } from "react-icons/ai";
import { api } from "../../../services/api";
import { Header } from "../../../components/Header";
import { PageTitle } from "../../../components/PageTitle";
import { AuthContext } from "../../../contexts/AuthContext";
import { FormButton } from "../../../components/FormButton";
import { SelectSingle } from "../../../components/SelectSingle";
import { SelectMultiple } from "../../../components/SelectMultiple";
import { DateHourPicker } from "../../../components/DateHourPicker";
import "react-datepicker/dist/react-datepicker.css";
import { ModalAddSector } from "../components/ModalAddSector";
import { ModalAddCostumer } from "../components/ModalAddCostumer";
import { Loading } from "../../../components/Loading";
import { BsCloudArrowUp } from "react-icons/bs";
import imageCompression from "browser-image-compression";

registerLocale("ptBR", ptBR);

interface SelectSectors {
  id: string;
  name: string;
}

interface SelectCostumers {
  id: string;
  name: string;
}

interface SelectServices {
  [x: string]: any;
  id: string;
  name: string;
}

interface SelectEquipments {
  id: string;
  name: string;
  grade: string;
}

interface FormServices {
  service_id: string;
}

interface FormEquipments {
  equipment_id: string;
  name: string;
  grade: string;
}

interface FormSectors {
  id: string;
  sector_id: string;
  equipments: FormEquipments[];
  observation: string;
  images: any;
  imagesPreview: any;
  show: boolean;
}

interface FormTechVisitReport {
  costumer_id: string;
  user_id: string;
  begin: string;
  end: string;
  observation: string;
  pending: string;
  sectors: FormSectors[];
  services: FormServices[];
  responsible_id: string;
  technician_id: string;
  responsible_signature: string;
  technician_signature: string;
}

const schema = Yup.object().shape({
  begin: Yup.string().required("Selecione uma data e hora"),
  end: Yup.string().required("Selecione uma data e hora"),
  costumer_id: Yup.string().required("Selecione um cliente"),
  services: Yup.array().of(Yup.string()).required("Selecione os serviços"),
  pending: Yup.string().required("Selecione se existe pendência ou não"),
});

export function TechVisitReportCreate() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [report, setReport] = useState<FormTechVisitReport>();
  const [selectSectors, setSelectSectors] = useState<SelectSectors[]>([]);
  const [selectCostumers, setSelectCostumers] = useState<SelectCostumers[]>([]);
  const [selectServices, setSelectServices] = useState<SelectServices[]>([]);
  const [selectEquipments, setSelectEquipments] = useState<FormEquipments[]>(
    [],
  );
  const [idCostumer, setIdCostumer] = useState("");
  const [modalAddSector, setModalAddSector] = useState(false);
  const [modalAddCostumer, setModalAddCostumer] = useState(false);
  const [onlyEntregaService, setOnlyEntregaService] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedServicesForm, setSelectedServicesForm] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (id !== undefined) {
      api.get(`techVisitReport/${id}`).then(response => {
        const serviceOptions = response.data.services.map(
          ({ id: service_id, name }: SelectServices) => {
            return {
              value: service_id,
              label: name,
            };
          },
        );

        const servicesIds = response.data.services.map(
          ({ id: service_id }: SelectServices) => {
            return service_id;
          },
        );

        const { begin, end } = response.data;

        setSelectedServicesForm(servicesIds);
        setSelectedServices(serviceOptions);
        setStartDate(new Date(begin));
        setEndDate(new Date(end));
        return setReport(response.data);
      });
    }
    setLoading(false);
  }, [refresh]);

  useEffect(() => {
    api
      .get("/costumers", {
        params: {
          status: "A",
        },
      })
      .then(response => {
        return setSelectCostumers(response.data);
      });
  }, [modalAddCostumer]);

  useEffect(() => {
    api
      .get("/services", {
        params: {
          status: "A",
        },
      })
      .then(response => {
        const serviceOptions = response.data.map(
          ({ id: service_id, name }: SelectServices) => {
            return {
              value: service_id,
              label: name,
            };
          },
        );

        return setSelectServices(serviceOptions);
      });

    api
      .get("/equipments", {
        params: {
          status: "A",
        },
      })
      .then(response => {
        const equipmentForm = response.data.map(
          (equipment: SelectEquipments) => {
            return {
              equipment_id: equipment.id,
              name: equipment.name,
              grade: "",
            };
          },
        );

        return setSelectEquipments(equipmentForm);
      });
  }, []);

  useEffect(() => {
    api
      .get("/sectors", {
        params: {
          costumer_id: idCostumer,
          status: "A",
        },
      })
      .then(response => {
        return setSelectSectors(response.data);
      });
  }, [idCostumer, modalAddSector]);

  async function handleSubmit({
    costumer_id,
    begin,
    end,
    observation,
    pending,
    sectors,
    services,
  }: FormTechVisitReport) {
    setLoading(true);
    const servicesFormated = services.map(service => {
      return { service_id: service };
    });

    if (id === undefined) {
      const { report_sector_ids, report_id } = await api
        .post("/techVisitReport", {
          costumer_id,
          user_id: user.id,
          begin,
          end,
          observation,
          pending,
          services: servicesFormated,
          sectors,
        })
        .then(response => {
          return response.data;
        })
        .catch(() => {
          setLoading(false);
        });

      const promise = sectors.map(async ({ sector_id, images }) => {
        const { report_sector_id } = report_sector_ids.find(
          element => element.sector_id === sector_id,
        );

        const promise2 = images.map(async file => {
          if (file.name) {
            const data = new FormData();

            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };

            const fileCompressed: any = await imageCompression(file, options);

            data.append("files", fileCompressed);

            await api
              .post(`/techVisitReport/sector_images/${report_sector_id}`, data)
              .then(() => {
                console.log("requisição finalizada");
              })
              .catch(() => {
                setLoading(false);
              });
          }
        });

        await Promise.all(promise2);
      });

      await Promise.all(promise);

      return setTimeout(() => {
        return onlyEntregaService === true
          ? navigate(`/tech-visit-reports`)
          : navigate(`/tech-visit-reports/confirm/${report_id}`);
      }, 3000);
    } else {
      const { report_sector_ids } = await api
        .put(`/techVisitReport/${id}`, {
          costumer_id,
          user_id: user.id,
          begin,
          end,
          observation,
          pending,
          services: servicesFormated,
          sectors,
        })
        .then(response => {
          return response.data;
        })
        .catch(() => {
          setLoading(false);
        });

      sectors.forEach(async ({ sector_id, images }) => {
        const { report_sector_id } = await report_sector_ids.find(
          element => element.sector_id === sector_id,
        );

        // await images.forEach(file => {
        //   const data = new FormData();

        //   data.append("files", file);

        //   api
        //     .post(`/techVisitReport/sector_images/${report_sector_id}`, data)
        //     .catch(() => {
        //       setLoading(false);
        //     });
        // });
      });

      return setTimeout(() => {
        return navigate(`/tech-visit-reports/confirm/${id}`);
      }, 3000);
    }
  }

  async function deleteImage({ id: image_id }) {
    setLoading(true);
    await api.delete(`/techVisitReport/sector_images/${image_id}`);

    setTimeout(() => {
      setRefresh(!refresh);
      return setLoading(false);
    }, 2000);
  }

  async function updateImage({ file, report_sector_id }) {
    setLoading(true);
    const data = new FormData();

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    const fileCompressed: any = await imageCompression(file, options);

    data.append("files", fileCompressed);

    await api.post(`/techVisitReport/sector_images/${report_sector_id}`, data);

    setTimeout(() => {
      setRefresh(!refresh);
      return setLoading(false);
    }, 2000);
  }

  function verifyServicesSelected(option, setFieldValue) {
    if (option.length === 1 && option[0].label === "Entrega") {
      setFieldValue("observation", "");
      setOnlyEntregaService(true);
    } else {
      setFieldValue(
        "observation",
        "Os demais setores e equipamentos estavam em bom estado de conservação e(ou) funcionamento.",
      );
      setOnlyEntregaService(false);
    }
  }

  const initialValues: FormTechVisitReport = !report
    ? {
        begin: "",
        end: "",
        costumer_id: "",
        user_id: user.id,
        services: [],
        sectors: [],
        pending: "",
        observation:
          "Os demais setores e equipamentos estavam em bom estado de conservação e(ou) funcionamento.",
        responsible_id: "",
        responsible_signature: "",
        technician_id: "",
        technician_signature: "",
      }
    : {
        begin: report.begin,
        end: report.end,
        costumer_id: report.costumer_id,
        user_id: report.user_id,
        services: selectedServicesForm,
        sectors: report.sectors,
        pending: report.pending,
        observation: report.observation,
        responsible_id: report.responsible_id,
        responsible_signature: report.responsible_signature,
        technician_id: report.technician_id,
        technician_signature: report.technician_signature,
      };

  return (
    <>
      {loading && <Loading />}
      <div className="h-full">
        <Header userName={user?.name} />
        <div className="md:w-auto xl:w-[400px] mx-6 my-5">
          <PageTitle
            title="Novo Relatório"
            backButton
            backPath="/tech-visit-reports"
          />
          {/* <div className="absolute rounded-lg bg-[#0085BD] right-0 top-0 p-6 overflow-hidden">
            <span className="text-xs text-white">
              Ocorreu um erro ao realizar a requisição, tente novamente.
            </span>
          </div> */}
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={schema}
            onSubmit={values => {
              handleSubmit(values);
            }}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form className="flex flex-col flex-wrap">
                <div className="">
                  {/* <button
                    type="button"
                    onClick={() => {
                      console.log(values);
                    }}
                  >
                    Initial Values
                  </button> */}
                  <p className="text-small text-white mt-2">
                    Data e hora do início da visita
                  </p>
                  <DateHourPicker
                    selected={startDate}
                    onChange={date => {
                      setStartDate(date);
                      setFieldValue("begin", format(date, "yyyy-MM-dd HH:mm"));
                    }}
                    timeInputLabel="Time:"
                    dateFormat="dd/MM/yyyy H:mm"
                    showTimeInput
                    error={errors.begin}
                  />
                </div>
                <div>
                  <p className="text-small text-white mt-2">Cliente</p>
                  <div className="flex gap-2">
                    <SelectSingle
                      name="costumer_id"
                      onChange={e => {
                        setFieldValue("costumer_id", e.target.value);
                        setIdCostumer(e.target.value);
                      }}
                      error={errors.costumer_id}
                    >
                      <option value="" selected disabled>
                        Selecione um cliente
                      </option>
                      {selectCostumers &&
                        selectCostumers.map(
                          ({ id: costumerId, name }: SelectCostumers) => (
                            <option key={costumerId} value={costumerId}>
                              {name}
                            </option>
                          ),
                        )}
                    </SelectSingle>
                    <button
                      type="button"
                      className="flex items-center justify-center h-[52px] w-10 text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                      onClick={() => setModalAddCostumer(true)}
                    >
                      <AiOutlinePlus size={16} />
                    </button>
                    {modalAddCostumer && (
                      <div
                        className={
                          !modalAddCostumer ? "hidden" : "relative z-10"
                        }
                        aria-labelledby="modal-title"
                        role="dialog"
                        aria-modal="true"
                      >
                        <ModalAddCostumer
                          setModalAddCostumer={setModalAddCostumer}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col">
                  <p className="text-small text-white mt-2">Serviços</p>
                  <SelectMultiple
                    name="services"
                    options={selectServices}
                    value={selectedServices}
                    isMulti
                    onChange={option => {
                      setSelectedServicesForm(option.map(item => item.value));
                      setSelectedServices(option.map(item => item));
                      setFieldValue(
                        "services",
                        option.map(item => item.value),
                      );
                      verifyServicesSelected(option, setFieldValue);
                    }}
                    error={errors.services}
                  />
                </div>
                <div>
                  <p className="text-small text-white mt-2">Setores</p>
                  <FieldArray
                    name="sectors"
                    render={arraySectors => (
                      <>
                        {values.sectors && values.sectors.length > 0 ? (
                          values.sectors.map(
                            ({ show, images }: FormSectors, indexSector) => (
                              <div className="h-fit mb-2">
                                <div className="grid grid-cols-2">
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        arraySectors.remove(indexSector)
                                      }
                                      className="relative top-[10px] right-[10px] p-1 bg-red-500 rounded-full"
                                    >
                                      <MdOutlineDelete
                                        size={20}
                                        className="text-white"
                                      />
                                    </button>
                                  </div>
                                  <div className="flex items-center justify-start">
                                    {show === true ? (
                                      <button
                                        type="button"
                                        className="relative top-[10px] right-[14px] p-1 bg-white rounded-full"
                                        onClick={() =>
                                          setFieldValue(
                                            `sectors[${indexSector}].show`,
                                            false,
                                          )
                                        }
                                      >
                                        <IoIosArrowUp size={20} />
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="relative top-[10px] right-[14px] p-1 bg-white rounded-full"
                                        onClick={() =>
                                          setFieldValue(
                                            `sectors[${indexSector}].show`,
                                            true,
                                          )
                                        }
                                      >
                                        <IoIosArrowDown size={20} />
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="bg-[#0085BD] w-full rounded-lg p-4 mt-[-9px]">
                                  <p className="text-small text-white">Setor</p>
                                  <div className="flex gap-2">
                                    <Field
                                      as="select"
                                      name={`sectors[${indexSector}].sector_id`}
                                      className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none"
                                    >
                                      <option value="" defaultChecked disabled>
                                        Selecione um setor
                                      </option>
                                      {selectSectors &&
                                        selectSectors.map(
                                          ({
                                            id: sectorId,
                                            name,
                                          }: SelectSectors) => (
                                            <option
                                              key={sectorId}
                                              value={sectorId}
                                            >
                                              {name}
                                            </option>
                                          ),
                                        )}
                                    </Field>
                                    <button
                                      type="button"
                                      className="flex items-center justify-center min-h-[56px] w-10 text-sm text-white rounded-lg bg-[#92C163] hover:bg-[#A4C880] transition-colors"
                                      onClick={() => setModalAddSector(true)}
                                    >
                                      <AiOutlinePlus size={16} />
                                    </button>
                                    {modalAddSector && (
                                      <div
                                        className={
                                          !modalAddSector
                                            ? "hidden"
                                            : "relative z-10"
                                        }
                                        aria-labelledby="modal-title"
                                        role="dialog"
                                        aria-modal="true"
                                      >
                                        <ModalAddSector
                                          setModalAddSector={setModalAddSector}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  {values.sectors[indexSector].show ===
                                    true && (
                                    <>
                                      <div className="mt-2 grid grid-cols-custom-5 text-white">
                                        <div>
                                          <p className="font-medium">
                                            Equipamentos
                                          </p>
                                        </div>
                                        <div>
                                          <p className="font-medium">O</p>
                                        </div>
                                        <div>
                                          <p className="font-medium">B</p>
                                        </div>
                                        <div>
                                          <p className="font-medium">R</p>
                                        </div>
                                        <div>
                                          <p className="font-medium" />
                                        </div>
                                        {selectEquipments.map(
                                          (
                                            { name: equipmentName },
                                            indexEquipment,
                                          ) => (
                                            <>
                                              <div>{equipmentName}</div>
                                              <div>
                                                <Field
                                                  type="radio"
                                                  value="O"
                                                  name={`sectors[${indexSector}].equipments[${indexEquipment}].grade`}
                                                />
                                              </div>
                                              <div>
                                                <Field
                                                  type="radio"
                                                  value="B"
                                                  name={`sectors[${indexSector}].equipments[${indexEquipment}].grade`}
                                                />
                                              </div>
                                              <div>
                                                <Field
                                                  type="radio"
                                                  value="R"
                                                  name={`sectors[${indexSector}].equipments[${indexEquipment}].grade`}
                                                />
                                              </div>
                                              <div>
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    setFieldValue(
                                                      `sectors[${indexSector}].equipments[${indexEquipment}].grade`,
                                                      "",
                                                    );
                                                  }}
                                                >
                                                  <MdOutlineDelete />
                                                </button>
                                              </div>
                                            </>
                                          ),
                                        )}
                                      </div>
                                      <p className="text-small font-medium text-white mt-2">
                                        Observações
                                      </p>
                                      <Field
                                        as="textarea"
                                        placeholder="..."
                                        name={`sectors[${indexSector}].observation`}
                                        className="h-[104px] p-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none resize-none scrollbar-thumb-[#0085BD] scrollbar-track-transparent scrollbar-thin"
                                      />
                                      <div>
                                        <aside className="flex flex-row flex-wrap gap-1">
                                          <FieldArray
                                            name={`sectors[${indexSector}].images`}
                                            render={arrayImages => (
                                              <>
                                                {images.map(
                                                  (file, indexImage) => {
                                                    return (
                                                      <div className="flex flex-col gap-1">
                                                        {file.file_name !==
                                                        undefined ? (
                                                          <img
                                                            className="w-16 h-12 overflow-hidden bg-white rounded-lg border-dashed border-2 border-[#0085BD] flex items-center justify-center"
                                                            src={`https://app-luvilimp.s3.amazonaws.com/techVisitReportSectorImages/${file.file_name}`}
                                                            alt=""
                                                          />
                                                        ) : (
                                                          <label
                                                            htmlFor={`sectors[${indexSector}].image[${indexImage}]`}
                                                            className="w-16 h-12 overflow-hidden bg-white rounded-lg border-dashed border-2 border-[#0085BD] flex items-center justify-center"
                                                          >
                                                            <BsCloudArrowUp className="text-[#0085BD]" />
                                                            {id === undefined &&
                                                              values.sectors[
                                                                indexSector
                                                              ].imagesPreview
                                                                .length > 0 && (
                                                                <img
                                                                  src={
                                                                    values
                                                                      .sectors[
                                                                      indexSector
                                                                    ]
                                                                      .imagesPreview[
                                                                      indexImage
                                                                    ]
                                                                  }
                                                                  alt=""
                                                                />
                                                              )}
                                                            <input
                                                              type="file"
                                                              id={`sectors[${indexSector}].image[${indexImage}]`}
                                                              className="hidden"
                                                              onChange={e => {
                                                                if (
                                                                  id ===
                                                                  undefined
                                                                ) {
                                                                  setFieldValue(
                                                                    `sectors[${indexSector}].images[${indexImage}]`,
                                                                    e
                                                                      .currentTarget
                                                                      .files[0],
                                                                  );
                                                                  setFieldValue(
                                                                    `sectors[${indexSector}].imagesPreview[${indexImage}]`,
                                                                    URL.createObjectURL(
                                                                      e.target
                                                                        .files[0],
                                                                    ),
                                                                  );
                                                                } else {
                                                                  updateImage({
                                                                    report_sector_id:
                                                                      values
                                                                        .sectors[
                                                                        indexSector
                                                                      ].id,
                                                                    file: e
                                                                      .target
                                                                      .files[0],
                                                                  });
                                                                }
                                                              }}
                                                            />
                                                          </label>
                                                        )}
                                                        <FiTrash
                                                          className="bg-white text-[#0085BD] rounded-lg p-1 text-xl cursor-pointer w-full"
                                                          onClick={() => {
                                                            if (
                                                              id === undefined
                                                            ) {
                                                              // values.sectors[
                                                              //   indexSector
                                                              // ].images.splice(
                                                              //   indexImage,
                                                              //   1,
                                                              // );
                                                              arrayImages.remove(
                                                                indexImage,
                                                              );
                                                              values.sectors[
                                                                indexSector
                                                              ].imagesPreview.splice(
                                                                indexImage,
                                                                1,
                                                              );
                                                            } else {
                                                              deleteImage({
                                                                id: file.id,
                                                              });
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    );
                                                  },
                                                )}
                                                <button
                                                  type="button"
                                                  onClick={() => {
                                                    arrayImages.push({});
                                                  }}
                                                >
                                                  <FiPlus className="h-full bg-white text-[#0085BD] rounded-lg p-1 text-xl cursor-pointer" />
                                                </button>
                                              </>
                                            )}
                                          />
                                        </aside>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            ),
                          )
                        ) : (
                          <div />
                        )}
                        <div className="w-10 h-8 mt-1 bg-[#0085BD] rounded-lg">
                          <button
                            type="button"
                            onClick={() => {
                              arraySectors.push({
                                sector_id: "",
                                equipments: selectEquipments,
                                observation: "",
                                show: true,
                                images: [{}],
                                imagesPreview: [],
                              });
                            }}
                            className="flex items-center justify-center w-full h-full"
                          >
                            <FiPlus size={20} className="text-white" />
                          </button>
                        </div>
                      </>
                    )}
                  />
                </div>
                <p className="text-small text-white mt-2">
                  Ficou alguma pendência?
                </p>
                <SelectSingle
                  name="pending"
                  defaultValue=""
                  onChange={e => setFieldValue("pending", e.target.value)}
                  error={errors.pending}
                >
                  <option value="" disabled>
                    Sim ou Não
                  </option>
                  <option value="true">Sim</option>
                  <option value="false">Não</option>
                </SelectSingle>
                <p className="text-small text-white mt-2">Notas</p>
                <Field
                  as="textarea"
                  placeholder="Observações gerais"
                  name="observation"
                  className="h-[104px] p-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none resize-none scrollbar-thumb-[#0085BD] scrollbar-track-transparent scrollbar-thin focus:ring-0 border-none"
                />
                <p className="text-small text-white mt-2">
                  Data e hora do término da visita
                </p>
                <DateHourPicker
                  selected={endDate}
                  onChange={date => {
                    setEndDate(date);
                    setFieldValue("end", format(date, "yyyy-MM-dd HH:mm"));
                  }}
                  timeInputLabel="Time:"
                  dateFormat="dd/MM/yyyy H:mm"
                  showTimeInput
                  error={errors.end}
                />
                <FormButton title="Salvar Rascunho" type="submit" />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

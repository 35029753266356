/* eslint-disable import/no-duplicates */
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

registerLocale("ptBR", ptBR);

function getClasses(error) {
  return error
    ? {
        field:
          "h-[52px] px-4 w-full rounded-lg text-red-500 border-2 border-red-500 bg-white focus:outline-none focus:outline-none focus:ring-0",
      }
    : {
        field:
          "h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:outline-none focus:ring-0 border-none",
      };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DateHourPicker({ ...props }: any) {
  return (
    <>
      <DatePicker className={getClasses(props.error).field} {...props} />
      <div className="text-xs text-red-500">{props.error}</div>
    </>
  );
}

/* eslint-disable import/no-extraneous-dependencies */
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { enqueueSnackbar } from "notistack";
import { Header } from "../../../components/Header";
import { FormButton } from "../../../components/FormButton";
import { Input } from "../../../components/Input";
import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { PageTitle } from "../../../components/PageTitle";
import { InputMask } from "../../../components/InputMask";
import { maskPhone } from "../../../components/masks/Masks";

interface Responsible {
  name: string;
  email: string;
  phone: number;
  costumer?: Costumer;
}

interface Costumer {
  id: string;
  name: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Campo responsável é obrigatório"),
  email: Yup.string()
    .email("E-mail inválido")
    .required("Campo e-mail é obrigatório"),
});

export function ResponsibleEdit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [responsible, setResponsible] = useState<Responsible>();
  const [costumers, setCostumers] = useState<Costumer[]>([]);
  const [phoneMask, setPhoneMask] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      api.get(`responsibles/${id}`).then(response => {
        return setResponsible(response.data);
      });
    }

    api.get("/costumers").then(response => {
      return setCostumers(response.data);
    });
  }, [id]);

  function handleSubmit(data: Responsible) {
    if (id) {
      api
        .put(`/responsibles/${id}`, data)
        .then(() => {
          enqueueSnackbar("Responsável editado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    } else {
      api
        .post("/responsibles", data)
        .then(() => {
          enqueueSnackbar("Responsável cadastrado com sucesso!", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        })
        .catch(({ response }) => {
          enqueueSnackbar(response.data.message, {
            variant: "warning",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        });
    }
    return navigate("/responsibles");
  }

  const initialValues = {
    name: responsible ? responsible.name : "",
    email: responsible ? responsible.email : "",
    phone: responsible ? responsible.phone : null,
    costumer_id: responsible ? responsible.costumer : "",
  };

  return (
    <div className="h-screen">
      <Header userName={user?.name} />
      <div className="md:w-auto xl:w-[400px] mx-6 mt-5">
        <PageTitle
          title={id ? "Editar Responsável" : "Novo Responsável"}
          backButton
          backPath="/responsibles"
        />
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          {({ errors, setFieldValue }) => (
            <Form>
              <span className="text-small text-white">Responsável</span>
              <Input
                type="text"
                name="name"
                placeholder="Responsável"
                error={errors.name}
              />
              <span className="text-small text-white">E-mail</span>
              <Input
                type="text"
                name="email"
                placeholder="E-mail"
                error={errors.email}
              />
              <span className="text-small text-white">Telefone</span>
              <InputMask
                value={phoneMask}
                onChange={e => {
                  setFieldValue("phone", e.target.value);
                  setPhoneMask(maskPhone(e.target.value));
                }}
                type="text"
                name="phone"
                placeholder="Telefone"
              />
              {!id && (
                <div className="mt-1">
                  <span className="text-small text-white">Cliente</span>
                  <Field
                    as="select"
                    name="costumer_id"
                    className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
                  >
                    <option value="" selected disabled>
                      Selecione um cliente
                    </option>
                    {costumers &&
                      costumers.map(({ id: costumer_id, name }: Costumer) => (
                        <option key={costumer_id} value={costumer_id}>
                          {name}
                        </option>
                      ))}
                  </Field>
                </div>
              )}
              <FormButton
                title={id ? "Editar Responsável" : "Cadastrar Responsável"}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { Header } from "../../../components/Header";
import { FormButton } from "../../../components/FormButton";
import { Input } from "../../../components/Input";
import { AuthContext } from "../../../contexts/AuthContext";
import { api } from "../../../services/api";
import { PageTitle } from "../../../components/PageTitle";
// import { InputMask } from "../../../components/InputMask";
// import { maskZIP } from "../../../components/masks/Masks";

interface Costumer {
  name: string;
  zip: string;
  address: string;
  number: string;
  complement: string;
  district: string;
  city: string;
  state: string;
  alert_days_without_report: number;
}

interface ResponseViaCep {
  logradouro: string;
  bairro: string;
  localidade: string;
  uf: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required("Campo cliente é obrigatório"),
  zip: Yup.string().required("Campo CEP é obrigatório"),
  address: Yup.string().required("Campo logradouro é obrigatório"),
  number: Yup.string().required("Campo número é obrigatório"),
  district: Yup.string().required("Campo bairro é obrigatório"),
  city: Yup.string().required("Campo cidade é obrigatório"),
  state: Yup.string().required("Campo estado é obrigatório"),
});

export function CostumerEdit() {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [costumer, setCostumer] = useState<Costumer>();
  // const [zipMask, setZipMask] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      api.get(`costumers/${id}`).then(response => {
        return setCostumer(response.data);
      });
    }
  }, [id]);

  function handleZip(zip: string, setFieldValue) {
    if (zip?.length !== 8) {
      return null;
    }

    axios
      .get<ResponseViaCep>(`https://viacep.com.br/ws/${zip}/json/`)
      .then(response => {
        setFieldValue("address", response.data.logradouro);
        setFieldValue("district", response.data.bairro);
        setFieldValue("city", response.data.localidade);
        setFieldValue("state", response.data.uf);
      });

    return null;
  }

  function handleSubmit(data: Costumer) {
    if (id) {
      api.put(`/costumers/${id}`, data).then(() => {
        enqueueSnackbar("Cliente editado com sucesso!", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
    } else {
      api.post("/costumers", data).then(() => {
        enqueueSnackbar("Cliente cadastrado com sucesso!", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
    }
    return navigate("/costumers");
  }

  const initialValues = {
    name: costumer ? costumer.name : "",
    zip: costumer ? costumer.zip : "",
    address: costumer ? costumer.address : "",
    number: costumer ? costumer.number : "",
    complement: costumer ? costumer.complement : "",
    district: costumer ? costumer.district : "",
    city: costumer ? costumer.city : "",
    state: costumer ? costumer.state : "",
    alert_days_without_report: costumer
      ? costumer.alert_days_without_report
      : 0,
  };

  return (
    <div className="h-screen">
      <Header userName={user?.name} />
      <div className="md:w-auto xl:w-[400px] mx-6 mt-5">
        <PageTitle
          title={id ? "Editar Cliente" : "Novo Cliente"}
          backButton
          backPath="/costumers"
        />
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          {({ errors, setFieldValue }) => (
            <Form>
              <span className="text-small text-white">Cliente</span>
              <Input
                type="text"
                name="name"
                placeholder="Nome do cliente"
                error={errors.name}
              />
              <span className="text-small text-white">
                Alerta de dias sem relatório
              </span>
              <Input
                type="number"
                name="alert_days_without_report"
                placeholder="Dias sem relatório"
                error={errors.alert_days_without_report}
              />
              <span className="text-small text-white">CEP</span>
              {/* <InputMask
                value={zipMask}
                onChange={e => {
                  setFieldValue("zip", e.target.value);
                  setZipMask(maskZIP(e.target.value));
                }}
                onBlur={e => handleZip(e.target.value, setFieldValue)}
                type="text"
                name="zip"
                placeholder="CEP"
                error={errors.zip}
              /> */}
              <Input
                type="text"
                name="zip"
                placeholder="CEP"
                onBlur={e => handleZip(e.target.value, setFieldValue)}
                error={errors.zip}
              />
              <span className="text-small text-white">Logradouro</span>
              <Input
                type="text"
                name="address"
                placeholder="Logradouro"
                error={errors.address}
              />
              <span className="text-small text-white">Número</span>
              <Input
                type="text"
                name="number"
                placeholder="Número"
                error={errors.number}
              />
              <span className="text-small text-white">Complemento</span>
              <Input
                type="text"
                name="complement"
                placeholder="Complemento"
                error={errors.complement}
              />
              <span className="text-small text-white">Bairro</span>
              <Input
                type="text"
                name="district"
                placeholder="Bairro"
                error={errors.district}
              />
              <span className="text-small text-white">Cidade</span>
              <Input
                type="text"
                name="city"
                placeholder="Cidade"
                error={errors.city}
              />
              <div className="mt-1">
                <span className="text-small text-white">Estado</span>
                <Field
                  as="select"
                  name="state"
                  className="h-[52px] px-4 w-full rounded-lg text-[#7FA5B2] bg-white focus:outline-none focus:ring-0 border-none"
                >
                  <option defaultChecked disabled value="">
                    Selecione o estado
                  </option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                  <option value="EX">Estrangeiro</option>
                </Field>
              </div>
              <FormButton
                title={id ? "Editar Cliente" : "Cadastrar Cliente"}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

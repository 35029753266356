import userImg from "../assets/user.png";

interface HeaderProps {
  userName: string;
}

export function Header({ userName }: HeaderProps) {
  return (
    <header className="h-[100px] w-full  flex items-center justify-between bg-[#92C163]">
      <div className="mx-6 flex flex-col">
        <span className="text-lg text-white">Bem-vindo,</span>
        <span className="text-xl font-medium text-white">{userName}</span>
      </div>
      <div className="mx-6">
        {/* <img className="w-[56px]" src={userImg} alt="" /> */}
      </div>
    </header>
  );
}

import { Form, Formik } from "formik";
import { useDropzone } from "react-dropzone";
import { FormButton } from "../../components/FormButton";
import { api } from "../../services/api";

interface UploadProps {
  setFieldValue(field: string, value: any): void;
}

function UploadComponent({ setFieldValue }: UploadProps) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file =>
        Object.assign(file, { preview: URL.createObjectURL(file) }),
      );
      setFieldValue("files", files);
    },
  });

  return (
    <div>
      {}
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <span className="text-sm text-white">Selecione as imagens</span>
      </div>
    </div>
  );
}

export function TestPage() {
  async function handleSubmit({ files }) {
    console.log(files);
    // await files.map(file => {
    //   const data = new FormData();
    //   data.append("files", file);

    //   api.post(
    //     "/techVisitReport/sector_images/bb88f795-4835-4d63-a050-e1597abdddb2",
    //     data,
    //   );

    //   return null;
    // });
  }

  return (
    <Formik
      initialValues={{ files: [] }}
      onSubmit={values => {
        handleSubmit(values);
      }}
    >
      {({ setFieldValue }) => (
        <Form>
          <UploadComponent setFieldValue={setFieldValue} />
          <FormButton title="Salvar Rascunho" type="submit" />
        </Form>
      )}
    </Formik>
  );
}
